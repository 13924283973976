import { useLocation, useNavigate } from "react-router-dom";
import BreadcrumbButton from "./BreadcrumbButton";

export default function Breadcrumbs() {
  const location = useLocation();

  const fullPath = location.pathname;

  const pathList = location.pathname.split("/");

  const navigate = useNavigate();

  return (
    <div className="w3-bar w3-light-gray w3-padding w3-small">
      <button
        className={"w3-bar-item w3-button w3-round-xxlarge w3-margin-right"}
        style={{
          fontWeight: "bold",
          backgroundColor: "lightblue",
          color: "black",
        }}
        onClick={() => navigate("/")}
      >
        Menu principal (autres modules)
      </button>
      {pathList.map(
        (x, i) =>
          x != "" && (
            <BreadcrumbButton
              item={x}
              bold={i == pathList.length - 1 ? true : false}
              index={i}
              pathList={pathList}
            />
          )
      )}
    </div>
  );
}
