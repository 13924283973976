import React, { useState, useEffect } from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import { connect } from "react-redux";
import { checkAuthenticated, load_user } from "../authsys/actions/auth";

function RootLayout(props, { user }) {
  useEffect(() => {
    props.checkAuthenticated();
    props.load_user();
  }, []);

  const [theSidebarContentStyle, settheSidebarContentStyle] = useState({
    display: "none",
  });

  const [theContentStyle, setTheContentStyle] = useState({
    marginLeft: "0%",
    marginBottom: "20px",
  });

  const [theNavButtonStyle, setTheNavButtonStyle] = useState({
    display: "inline-block",
    position: "relative",
  });

  function openHamburger() {
    settheSidebarContentStyle({
      display: "block",
      width: "15%",
    });
    setTheContentStyle({
      marginLeft: "15%",
    });
    setTheNavButtonStyle({
      display: "none",
    });
  }

  function closeHamburger() {
    settheSidebarContentStyle({
      display: "none",
    });
    setTheContentStyle({
      marginLeft: "0%",
    });
    setTheNavButtonStyle({
      display: "inline-block",
    });
  }

  return (
    <div className="root-layout">
      {/* <div className="theSidebar" style={{ position: "fixed" }}>
        <button
          id="theNavButton"
          style={theNavButtonStyle}
          className="w3-button w3-red w3-xlarge"
          onClick={openHamburger}
        >
          &#9776;
        </button>
        <div
          className="w3-sidebar w3-bar-block w3-border-right"
          style={theSidebarContentStyle}
          id="theSidebarContent"
        >
          <button onClick={closeHamburger} className="w3-bar-item w3-large">
            Fermer &times;
          </button>
          <NavLink
            to="/"
            id="theNavLinkModule"
            style={{ textDecoration: "none" }}
            className="w3-bar-item w3-button"
          >
            Acceuil <i class="fa fa-home w3-red" aria-hidden="true" />
          </NavLink>
          {user && user.accessVentes > 0 ? (
            <NavLink
              to="ventes"
              id="theNavLinkModule"
              style={{ textDecoration: "none" }}
              className="w3-bar-item w3-button"
            >
              Ventes <i class="fa fa-money" aria-hidden="true" />
            </NavLink>
          ) : (
            ""
          )}
          {user && user.accessOperations > 0 ? (
            <NavLink
              to="operations"
              id="theNavLinkModule"
              style={{ textDecoration: "none" }}
              className="w3-bar-item w3-button"
            >
              Operations <i class="fa fa-cog" aria-hidden="true" />
            </NavLink>
          ) : (
            ""
          )}
          {user && user.accessPersonnel > 0 ? (
            <NavLink
              to="personnel"
              id="theNavLinkModule"
              style={{ textDecoration: "none" }}
              className="w3-bar-item w3-button"
            >
              Personnel <i class="fa fa-users" aria-hidden="true" />
            </NavLink>
          ) : (
            ""
          )}

          <NavLink
            to={process.env.REACT_APP_THE_HOST + "/admin/"}
            id="theNavLinkModule"
            style={{ textDecoration: "none" }}
            className="w3-bar-item w3-button w3-red w3-display-bottomleft w3-margin-bottom"
          >
            Mode Admin <i class="fa fa-cogs" aria-hidden="true" />
          </NavLink>
        </div>
      </div> */}

      <div className="theContent" style={theContentStyle}>
        <Outlet />
      </div>
      {props.children}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(null, { checkAuthenticated, load_user })(RootLayout);
