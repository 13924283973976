import {
  NavLink,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import useAPIData from "../../../functions/useAPIData";
import generique_employe from "../../../data_et_files/generique_article.png";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
export default function ProduitDetails() {
  // const loc = useLocation().pathname

  const { id } = useParams();

  const entity = useLoaderData();

  const thisEntity = entity.filter((obj) => obj.id == id)[0];

  const goTo_modifier_info = useLocation().pathname.replace(
    "details",
    "modifier"
  );
  const navigate = useNavigate();

  const toPrint = useRef(null);

  const handlePrint = useReactToPrint({
    documentTitle: "Details du produit " + thisEntity.id,
    removeAfterPrint: true,
  });

  const thisClasse = useAPIData("classeP").filter(
    (obj) => obj.id == thisEntity.classe
  )[0];
  const thisCouleur = useAPIData("couleurP").filter(
    (obj) => obj.id == thisEntity.couleur
  )[0];
  const thisUnite = useAPIData("unite").filter(
    (obj) => obj.id == thisEntity.unite
  )[0];
  let display_entity_photo = null;

  if (thisEntity.photo) {
    display_entity_photo = thisEntity.photo.replace("media", "static/media");
  }

  // let bP = <Barcode value={thisEntity.codebarre} options={{format:'ean13'}} renderer="image" />

  return (
    <div className="theEmployeDetails">
      <button
        onClick={() => {
          handlePrint(null, () => toPrint.current);
        }}
        className="w3-btn w3-ripple w3-pale-blue w3-round w3-margin-right"
        style={{ width: "300px" }}
      >
        <i className="fa fa-print" aria-hidden="true"></i> Imprimer
      </button>
      <button
        onClick={() => navigate("modifier")}
        className="w3-btn w3-ripple w3-yellow w3-round"
        style={{ width: "300px" }}
      >
        <i class="fa fa-pencil" aria-hidden="true"></i> Modifier
      </button>
      <div className="toPrint" ref={toPrint}>
        <div
          className="w3-panel w3-text-white"
          style={{ backgroundColor: "gray" }}
        >
          <h3>
            Details du produit:{" "}
            <strong>
              {thisEntity && thisEntity.designation} (
              {thisCouleur && thisCouleur.couleur})
            </strong>
          </h3>
        </div>
        <div className="w3-card-4 w3-bar">
          <div className="w3-bar-item w3-container w3-cell w3-center w3-padding-16">
            <p>
              <button
                style={{
                  backgroundColor: "white",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={() => navigate("modifier-photo")}
              >
                <img
                  src={
                    display_entity_photo
                      ? display_entity_photo
                      : generique_employe
                  }
                  className="w3-hide-small w3-border"
                  style={{ width: "200px", height: "200px" }}
                  alt="#"
                />
                <br />
                Cliquer pour modifier l'image
              </button>
            </p>
          </div>

          <div className="w3-bar-item w3-container w3-cell">
            {/* <p>
            Designation: <strong>{thisEntity && thisEntity.designation}</strong>
          </p> */}
            <p>
              Reference: <strong>{thisEntity && thisEntity.reference}</strong>
            </p>

            <p>
              Couleur: <strong>{thisCouleur && thisCouleur.couleur}</strong>
            </p>
            <p>Categorie: {thisClasse && thisClasse.classe}</p>
            <p>Unite: {thisUnite && thisUnite.unite}</p>

            <p>Details additionnels: {thisEntity && thisEntity.infoAdd}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
