import { useState } from "react";
import {
  Form,
  Link,
  NavLink,
  Outlet,
  redirect,
  useActionData,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import useAPIData from "../../../functions/useAPIData";
import sendProjetFormData from "../../../functions/ventes/sendProjetFormData";

export default function ProjetForm(props) {
  const { id } = useParams(); //get parameters

  const entity = useLoaderData(); //articles

  const thisEntity = entity.filter((obj) => obj.id == id)[0];

  //Load other data sources (from API) as needed
  // const entity = useAPIData("client");
  const responsables = useAPIData("employe");

  const thisClient = useAPIData("client").filter((obj) => obj.id == id)[0];

  //Variables for handling form
  let initValue = {};
  if (props.new_ind == "N") {
    initValue = { ...thisEntity };
  } else if (props.new_ind == "Y" && id > 0) {
    initValue = {
      client: id,
    };
  }

  const [formData, setFormData] = useState(initValue);

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData((prevValues) => ({ ...prevValues, [name]: value }));
  }

  function handleSubmit(event) {
    event.preventDefault();

    console.log(formData);

    // Conditional handling of the form
    if (props.new_ind == "Y") {
      // console.log(formData);
      // If new object
      sendProjetFormData(
        formData,
        process.env.REACT_APP_THE_HOST + "/api/projet/",
        "POST",
        "new"
      );
    } else if (props.new_ind == "N") {
      // If existing object
      sendProjetFormData(
        formData,
        process.env.REACT_APP_THE_HOST + "/api/projet/" + formData.id + "/",
        "PATCH",
        "existing"
      );
    }
  }
  return (
    <div className="repertoire-employe-layout">
      <div style={{ width: "800px" }}>
        {/* The selection content */}

        <form method="post" onSubmit={handleSubmit}>
          <div
            className="w3-panel w3-text-white"
            style={{ backgroundColor: "gray" }}
          >
            {props.new_ind == "Y" ? (
              id > 0 ? (
                <h3>
                  Nouveau projet pour client{" "}
                  <b>
                    {thisClient &&
                      thisClient.prenom +
                        " " +
                        thisClient.nom +
                        "/ " +
                        thisClient.nomEntreprise}
                  </b>
                  <p>
                    Veuillez fournir les infos ci-dessous{" "}
                    <span style={{ color: "red" }}>(requis)</span>
                  </p>
                </h3>
              ) : (
                <h3>
                  <p>
                    Veuillez fournir les infos ci-dessous{" "}
                    <span style={{ color: "red" }}>(requis)</span>
                  </p>
                </h3>
              )
            ) : (
              <h3>
                Modifier projet du client &nbsp;
                <b>
                  {thisEntity.clientPrenom +
                    " " +
                    thisEntity.clientNom +
                    "/ " +
                    thisEntity.clientEntreprise}{" "}
                </b>
                <p>Veuillez fournir les infos ci-dessous</p>
              </h3>
            )}
          </div>
          <p>
            <label className="w3-text-black">
              <b>
                <span
                  style={
                    props.new_ind == "Y" ? { color: "red" } : { color: "black" }
                  }
                >
                  Nom du projet
                </span>
              </b>
            </label>
            <input
              className="w3-input w3-border w3-sand"
              name="nom"
              type="text"
              value={formData.nom}
              onChange={handleChange}
              required={true}
            />
          </p>
          <p>
            {(() => {
              if (props.new_ind == "Y") {
                <div>
                  <label className="w3-text-black">
                    <b>
                      <span
                        style={
                          props.new_ind == "Y"
                            ? { color: "red" }
                            : { color: "black" }
                        }
                      >
                        Client:{" "}
                      </span>
                    </b>
                  </label>
                  {thisClient &&
                    thisClient.prenom +
                      " " +
                      thisClient.nom +
                      "/ " +
                      thisClient.nomEntreprise}
                </div>;
              }
            })()}
          </p>
          <p>
            <label className="w3-text-black">
              <b>
                <span
                  style={
                    props.new_ind == "Y" ? { color: "red" } : { color: "black" }
                  }
                >
                  Responsable Vial-Mali:{" "}
                </span>
              </b>
            </label>
            &nbsp;
            <select
              name="responsable"
              value={formData.responsable}
              onChange={handleChange}
              required
            >
              <option value={""}>selectionner employe</option>
              {responsables.map((x) => (
                <option key={x.id} value={x.numeroEmploye}>
                  {x.prenom + " " + x.nom}
                </option>
              ))}
            </select>
          </p>
          <p>
            <label className="w3-text-black">
              <b>
                <span
                  style={
                    props.new_ind == "Y" ? { color: "red" } : { color: "black" }
                  }
                >
                  Date de debut
                </span>
              </b>
            </label>
            <input
              className="w3-input w3-border w3-sand"
              name="dateDebut"
              type="date"
              value={formData.dateDebut}
              onChange={handleChange}
              required={true}
            />
          </p>
          <p>
            <label className="w3-text-black">
              <b>
                <span
                  style={
                    props.new_ind == "Y" ? { color: "red" } : { color: "black" }
                  }
                >
                  Date de fin
                </span>
              </b>
            </label>
            <input
              className="w3-input w3-border w3-sand"
              name="dateFin"
              type="date"
              value={formData.dateFin}
              onChange={handleChange}
              required={true}
            />
          </p>
          <p>
            <label className="w3-text-black">
              <b>
                <span>Details sur le projet</span>
              </b>
            </label>
            <textarea
              className="w3-input w3-border w3-sand"
              name="details"
              type="text"
              value={formData.details}
              onChange={handleChange}
            />
          </p>

          <button
            className="w3-btn w3-ripple w3-green w3-round"
            style={{ width: "500px" }}
            name="action"
            type="submit"
          >
            Soumettre
          </button>
          <button
            className="w3-btn w3-ripple w3-light-grey w3-round w3-margin-left"
            style={{ width: "284px" }}
            onClick={() =>
              props.new_ind == "Y"
                ? window.location.replace(
                    process.env.REACT_APP_THE_HOST + "/ventes/projets"
                  )
                : window.location.replace(
                    process.env.REACT_APP_THE_HOST +
                      "/ventes/projets/" +
                      id +
                      "/details"
                  )
            }
          >
            Annuler
          </button>
        </form>

        <p></p>

        <p></p>
      </div>
    </div>
  );
}
