import {
  NavLink,
  useLoaderData,
  useLocation,
  useParams,
} from "react-router-dom";
import useAPIData from "../../../functions/useAPIData";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";

import logoVial from "../../../data_et_files/vial_mali_logo.png";

function sortFunction(v1, v2) {
  return v1.numero - v2.numero;
}

// const formatCurrency = (number, symbol = "FCFA") => {
//   // Add thousands separator
//   const formattedNumber = number
//     .toString()
//     .replace(/\B(?=(\d{3})+(?!\d))/g, ".");

//   // Format the number as a currency string
//   return `${formattedNumber} ${symbol}`;
// };

const formatCurrency = (number) => {
  // Add thousands separator
  const formattedNumber = number
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  // Format the number as a currency string
  return `${formattedNumber}`;
};

export default function TransactionsDetails() {
  // const loc = useLocation().pathname

  const { id } = useParams();

  const entity = useLoaderData();

  const thisEntity = entity.filter((obj) => obj.id == id)[0];

  const goTo_modifier_info = useLocation().pathname.replace(
    "details",
    "modifier"
  );
  const thisClient = useAPIData("client").filter(
    (obj) => obj.id == thisEntity.client
  )[0];
  const theseProduit = useAPIData("transactionVenteProduit").filter(
    (obj) => obj.transaction == id
  );

  theseProduit.sort(sortFunction);

  // Create styles
  const styles = StyleSheet.create({
    page: {
      backgroundColor: "white",
      paddingVertical: "30px",
      paddingHorizontal: "40px",
    },
    footer: {
      position: "absolute",
      left: 35,
      bottom: 10,
      width: "100%",
      color: "red",
      fontSize: "10px",
      // textAlign: "center",
    },
    table: {
      width: "100%",
      fontSize: "15px",
    },
    row: {
      display: "flex",
      flexDirection: "row",
    },
    rowT: {
      display: "flex",
      flexDirection: "row",
      borderTop: "1px solid #EEE",
      paddingTop: 8,
      paddingBottom: 8,
    },
    rowI: {
      display: "flex",
      flexDirection: "row",
      borderTop: "1px solid #EEE",
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: "15px",
    },
    header: {
      borderTop: "none",
    },
    bold: {
      fontWeight: "bold",
    },
    row15: {
      width: "15%",
      textAlign: "center",
    },
    row15M: {
      width: "15%",
      textAlign: "right",
    },
    row40: {
      width: "40%",
    },
    row50: {
      width: "50%",
    },
    row85: {
      width: "85%",
    },
  });

  // Create Document Component
  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={[styles.table, styles.row]}>
          <View style={styles.row50}>
            <Image
              src={logoVial}
              style={{
                width: "100px",
                height: "50px",
                alignSelf: "flex-end",
                marginRight: "40px",
              }}
            />
          </View>
          <Text
            style={[
              styles.row50,
              { fontSize: "50px", marginLeft: "-37px", marginBottom: "10px" },
            ]}
          >
            Vial-Mali
          </Text>
        </View>
        <Text>____________________________________________________</Text>
        <Text style={{ marginTop: "10px" }}>Vente No. {id}</Text>
        <Text style={{ marginTop: "10px" }}>
          Doit:{" "}
          {(thisClient && thisClient.nomEntreprise) ||
            (thisClient && thisClient.prenom + " " + thisClient.nom)}
        </Text>
        <Text style={{ fontSize: "15px", alignSelf: "flex-end" }}>
          Bamako, {thisEntity.date}
        </Text>
        <Text style={{ fontSize: "15px", alignSelf: "flex-end" }}>
          Agent commercial: {thisEntity.agentCommercialPrenom}{" "}
          {thisEntity.agentCommercialNom}
        </Text>

        <Text>____________________________________________________</Text>
        <View style={styles.table}>
          <View style={[styles.rowT, styles.bold, styles.header]}>
            <Text style={styles.row15}>No.</Text>
            <Text style={styles.row40}>Designation</Text>
            <Text style={styles.row15}>Qtites</Text>
            <Text style={styles.row15}>Prix U</Text>
            <Text style={styles.row15M}>Montant</Text>
          </View>
          {theseProduit &&
            theseProduit.map((row, i) => {
              return (
                <View key={i} style={styles.rowI} wrap={false}>
                  <Text style={styles.row15}>{row.numero}</Text>
                  <Text style={styles.row40}>
                    <Text style={styles.bold}>{row.designation}</Text>
                    <Text style={styles.bold}>
                      ({row.largeur + "x" + row.hauteur})
                    </Text>
                  </Text>
                  <Text style={styles.row15}>{row.quantite}</Text>
                  <Text style={styles.row15}>
                    {formatCurrency(row.prixUnitaire ? row.prixUnitaire : 0)}
                  </Text>
                  <Text style={styles.row15M}>
                    {formatCurrency(row.montant ? row.montant : 0)}
                  </Text>
                </View>
              );
            })}
        </View>
        <View style={[styles.table, styles.rowT, { marginTop: "25px" }]}>
          <Text style={[styles.row85, { textAlign: "center" }]}>
            MONTANT TOTAL HORS TVA
          </Text>
          <Text style={styles.row15M}>
            {formatCurrency(
              thisEntity.montantBrut ? thisEntity.montantBrut : 0
            )}
          </Text>
        </View>
        <View style={[styles.table, styles.rowT]}>
          <Text style={[styles.row85, { textAlign: "center" }]}>
            REMISE {thisEntity.remisePct}%
          </Text>
          <Text style={styles.row15M}>
            {formatCurrency(
              thisEntity.montantRemise ? thisEntity.montantRemise : 0
            )}
          </Text>
        </View>
        <View style={[styles.table, styles.rowT]}>
          <Text style={[styles.row85, { textAlign: "center" }]}>
            NET A PAYER / APRES REMISE HORS TVA
          </Text>
          <Text style={styles.row15M}>
            {formatCurrency(thisEntity.montantNet ? thisEntity.montantNet : 0)}
          </Text>
        </View>
        <View
          style={[
            styles.table,
            styles.row,
            { textAlign: "center", marginTop: "50px", fontSize: "25px" },
          ]}
        >
          <Text style={styles.row50}>Le client</Text>
          <Text style={styles.row50}>Le fournisseur</Text>
        </View>
        <View style={styles.footer}>
          <Text style={{ textAlign: "center" }}>
            RUE TITI NIARE-PN°1141- RC 2483 - TEL : 66-74-76-28 / 76 40 34 81 /
            BCI 021109360009
          </Text>
          <Text style={{ textAlign: "center" }}>
            BMS 000307 011 01- 50 BDM 020401005720-10 FISCAL 082 200 345 J
            BAMAKO
          </Text>
        </View>
      </Page>
    </Document>
  );

  return (
    <div className="theEmployeDetails">
      {/* <NavLink
        to="/"
        className="w3-btn w3-ripple w3-pale-blue w3-round"
        style={{ width: "300px" }}
      >
        <i class="fa fa-print" aria-hidden="true"></i> Imprimer
      </NavLink> */}
      <NavLink
        to={goTo_modifier_info}
        className="w3-btn w3-ripple w3-yellow w3-round w3-margin-left"
        style={{ width: "300px" }}
      >
        <i class="fa fa-pencil" aria-hidden="true"></i> Modifier
      </NavLink>

      <hr />

      <div className="w3-display-container">
        <div
          className="w3-container w3-display-topmiddle"
          style={{ width: "100%" }}
        >
          <PDFViewer style={{ width: "100%", minHeight: "100vh" }}>
            <MyDocument />
          </PDFViewer>
        </div>
      </div>
    </div>
  );
}
