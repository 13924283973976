import {
  NavLink,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import useAPIData from "../../../functions/useAPIData";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import CarteEmploye from "../../../components/CarteEmploye";
import Barcode from "react-jsbarcode";
// import deleteEmployeData from "../../../functions/personnel/deleteEmployeData";

export default function CarteDetails() {
  // const loc = useLocation().pathname

  const { numeroEmploye } = useParams();

  const employes = useLoaderData();

  const employe = employes.filter(
    (obj) => obj.numeroEmploye == numeroEmploye
  )[0];

  const navigate = useNavigate();

  const goTo_assigner_carte = useLocation().pathname.replace(
    "details/carte-employe",
    "assigner-carte"
  );

  const carteEmploye = useAPIData("carteEmploye").filter(
    (x) => x.employe == numeroEmploye && x.indActive == "O"
  )[0];

  const numeroCarte = useAPIData("cartePhysique").filter(
    (x) => carteEmploye && x.id == carteEmploye.cartePhysique
  )[0];

  const toPrint = useRef(null);

  const handlePrint = useReactToPrint({
    documentTitle: "Fiche d'employé de" + employe.prenom + " " + employe.nom,
    removeAfterPrint: true,
  });
  const poste = useAPIData("poste").filter((obj) => obj.id == employe.poste)[0];
  const departement = useAPIData("departement").filter(
    (obj) => obj.id == employe.departement
  )[0];
  const responsable = useAPIData("employe").filter(
    (obj) => obj.numeroEmploye == employe.responsable
  )[0];

  let display_employe_photo = null;

  if (employe.photo) {
    display_employe_photo = employe.photo.replace("media", "static/media");
  }

  return (
    <div className="theEmployeDetails">
      <button
        onClick={() => navigate(goTo_assigner_carte)}
        className="w3-btn w3-ripple w3-green w3-round"
        style={{ width: "300px" }}
      >
        <i className="fa fa-id-card-o" aria-hidden="true"></i> Assigner carte
        physique
      </button>
      <button
        onClick={() => {
          handlePrint(null, () => toPrint.current);
        }}
        className="w3-btn w3-ripple w3-green w3-round w3-margin-left"
        style={{ width: "300px" }}
      >
        <i className="fa fa-id-card-o" aria-hidden="true"></i> Carte perdue
      </button>
      <button
        onClick={() => {
          handlePrint(null, () => toPrint.current);
        }}
        className="w3-btn w3-ripple w3-green w3-round w3-margin-left"
        style={{ width: "300px" }}
      >
        <i className="fa fa-id-card-o" aria-hidden="true"></i> Apporter des
        modifications
      </button>
      <div className="w3-row">
        <div className="toPrint" ref={toPrint}>
          <CarteEmploye
            photo={display_employe_photo}
            numero={numeroEmploye}
            prenom={employe && employe.prenom}
            nom={employe && employe.nom}
            dateEmbauche={employe && employe.dateEmbauche}
            departement={departement && departement.nom}
          />
        </div>
        <div className="w3-quarter w3-margin-left">
          {numeroCarte && numeroCarte ? (
            <div>
              <p>Numero de carte: {numeroCarte.numeroCarte}</p>

              <p>
                <Barcode
                  value={numeroCarte.numeroCarte}
                  options={{ format: "ean13" }}
                  renderer="image"
                  style={{ width: 500 }}
                />
              </p>
            </div>
          ) : (
            <p>Pas de carte assignee et active</p>
          )}
        </div>
      </div>
    </div>
  );
}
