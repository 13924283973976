import {
  NavLink,
  Navigate,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import useAPIData from "../../../functions/useAPIData";
import { useState } from "react";
import sendTransactionStockFormData from "../../../functions/operations/sendTransactionStockFormData";
import swal from "sweetalert";
import { connect } from "react-redux";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import LoadingSpinner from "../../../components/LoadingSpinner";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
function StockDetails({ user }) {
  // const loc = useLocation().pathname

  const { articleId } = useParams();

  const stock = useLoaderData();

  const thisStock = stock.filter((obj) => obj.article == articleId);

  const article = useAPIData("article").filter((obj) => obj.id == articleId)[0];

  const unite = useAPIData("unite").filter(
    (obj) => article && obj.id == article.unite
  )[0];

  const couleur = useAPIData("couleurA").filter(
    (obj) => article && obj.id == article.couleur
  )[0];

  const magasin = useAPIData("magasin");

  const transactions = useAPIData("transactionStock").filter(
    (obj) => obj.article == articleId
  );

  const toPrint = useRef(null);

  const handlePrint = useReactToPrint({
    documentTitle: "Etats du stock " + articleId,
    removeAfterPrint: true,
  });

  function getDefaultUnite(uniteN) {
    const thisUnite = unite && unite.filter((x) => x.unite == uniteN)[0];

    return thisUnite && thisUnite.unite;
  }
  function getDefaultUniteId(uniteN) {
    const thisUnite = unite && unite.filter((x) => x.unite == uniteN)[0];

    return thisUnite && thisUnite.id;
  }

  function getMagasin(magasinId) {
    const thisMagasin = magasin && magasin.filter((x) => x.id == magasinId)[0];

    return thisMagasin && thisMagasin.nom;
  }

  const navigate = useNavigate();

  const [modalApprov, setModalApprov] = useState({
    display: "none",
    showing: 0,
  });

  const [modalRetirer, setModalRetirer] = useState({
    display: "none",
    showing: 0,
  });

  const [formData, setFormData] = useState({
    article: articleId,
    indApprovision: "A",
  });

  const [formDataR, setFormDataR] = useState({
    article: articleId,
    indApprovision: "R",
  });

  function ajoutQuantite(event) {
    const { name, value } = event.target;
    setFormData((prevValues) => ({ ...prevValues, [name]: value }));
  }

  function retraitQuantite(event) {
    const { name, value } = event.target;
    setFormDataR((prevValues) => ({ ...prevValues, [name]: value }));
  }

  function handleSubmit(event, type) {
    event.preventDefault();

    const s = stock.filter(
      (obj) =>
        (obj.article == formData.article &&
          obj.magasin == formData.magasin &&
          obj.unite == formData.unite) ||
        (obj.article == formDataR.article &&
          obj.magasin == formDataR.magasin &&
          obj.unite == formDataR.unite)
    )[0];

    if (type == "r" && !s) {
      swal({
        text: "Quantité en stock insuffisante pour cette transaction!",
        icon: "error",
        buttons: false,
      });
      setTimeout(function () {
        window.location.replace(
          process.env.REACT_APP_THE_HOST +
            "/operations/stock/" +
            articleId +
            "/details"
        );
      }, 1500);
    } else if (type == "r" && formDataR.quantite > s.quantiteCourante) {
      swal({
        text: "Quantité en stock insuffisante pour cette transaction!",
        icon: "error",
        buttons: false,
      });
      setTimeout(function () {
        window.location.replace(
          process.env.REACT_APP_THE_HOST +
            "/operations/stock/" +
            articleId +
            "/details"
        );
      }, 1500);
    } else if (type == "a")
      sendTransactionStockFormData(
        {
          utilisateur: user && user.employeNumber,
          ...formData,
        },
        process.env.REACT_APP_THE_HOST + "/api/transactionStock/"
      );
    else if (type == "r")
      sendTransactionStockFormData(
        {
          utilisateur: user && user.employeNumber,
          ...formDataR,
        },
        process.env.REACT_APP_THE_HOST + "/api/transactionStock/"
      );
  }

  return !user ? (
    <LoadingSpinner color="blue" show={true} />
  ) : (
    <div className="theEmployeDetails">
      {user.accessOperations < 3 ? (
        ""
      ) : (
        <div className="w3-margin-bottom">
          <div
            className="w3-btn w3-ripple w3-light-gray w3-round w3-border"
            style={{ width: "300px" }}
            onClick={() => setModalRetirer({ display: "block", showing: 1 })}
          >
            <i className="fa fa-minus-circle" aria-hidden="true"></i> Retirer
            (manuellement)
          </div>
          <div
            className="w3-btn w3-ripple w3-light-gray w3-round w3-border w3-margin-left"
            style={{ width: "300px" }}
            onClick={() => setModalApprov({ display: "block", showing: 1 })}
          >
            <i className="fa fa-plus-circle" aria-hidden="true"></i>{" "}
            Approvisionner (manuellement)
          </div>
        </div>
      )}
      <button
        onClick={() => {
          handlePrint(null, () => toPrint.current);
        }}
        className="w3-btn w3-ripple w3-pale-blue w3-round w3-margin-right"
        style={{ width: "300px" }}
      >
        <i className="fa fa-print" aria-hidden="true"></i> Imprimer
      </button>

      <div className="toPrint" ref={toPrint}>
        <div>
          <div
            className="w3-panel w3-text-white"
            style={{ backgroundColor: "gray" }}
          >
            <h3>
              Etat du stock de:{" "}
              <strong>
                {article && article.designation} ({couleur && couleur.couleur})
                - {article && article.fabriquant}
              </strong>
            </h3>
          </div>
          <div className="w3-card-4 w3-bar">
            <table className="w3-table-all w3-hoverable">
              <thead>
                <tr className="w3-light-grey">
                  <th>Magasin</th>
                  <th>Quantite disponible</th>
                  <th>Date derniere mis-a-jour</th>
                </tr>
              </thead>
              <tbody>
                {thisStock.map((s) => {
                  {
                    /* const m = getMagasin(s.magasin); */
                  }
                  return (
                    <tr
                      onClick={() =>
                        navigate(
                          article && "/operations/articles/" + article.codebarre
                        )
                      }
                    >
                      <td>{s.magasin_display}</td>

                      <td
                        style={{
                          backgroundColor:
                            s.quantiteCourante > 0 ? "lightgreen" : "orange",
                        }}
                      >
                        <strong>
                          {s.quantiteCourante} &nbsp;
                          {unite && unite.unite}
                        </strong>
                      </td>

                      <td>{s.dateDerniereMisAJour}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <hr />
          <div>
            <div className="w3-panel w3-light-gray">
              <h3>Entrees et sorties</h3>
            </div>
            <div
              className="the-table"
              // style={{ height: 720, overflow: "scroll" }}
            >
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell className="w3-sand">Designation</TableCell> */}
                      <TableCell className="w3-light-gray">Date</TableCell>
                      <TableCell className="w3-light-gray">Magasin</TableCell>
                      <TableCell align="right" className="w3-light-gray">
                        Quantite
                      </TableCell>
                      <TableCell className="w3-light-gray">
                        Observation
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {transactions &&
                      transactions.map((row) => (
                        <TableRow
                          key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          {/* <TableCell>{row.designation}</TableCell> */}
                          <TableCell>{row.date}</TableCell>
                          <TableCell>{row.magasin_display}</TableCell>

                          {row.indApprovision == "R" ? (
                            <TableCell align="right" className="w3-pale-red">
                              {row.quantite} {row.unite}
                            </TableCell>
                          ) : (
                            <TableCell align="right" className="w3-pale-green">
                              {row.quantite} {row.unite}
                            </TableCell>
                          )}

                          <TableCell>{row.desc}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>

      <div
        id="approvisionner"
        class="w3-modal"
        style={{ display: modalApprov.display }}
      >
        <div class="w3-modal-content" style={{ width: "500px" }}>
          <header class="w3-container w3-green">
            <span
              onClick={() => setModalApprov({ display: "none", showing: 0 })}
              class="w3-button w3-display-topright"
            >
              &times;
            </span>
            <h2>Entrez la quantité a ajouter</h2>
          </header>
          <div class="w3-container w3-padding">
            <form onSubmit={(e) => handleSubmit(e, "a")}>
              <p>
                <label className="w3-text-black">
                  <b>
                    <span style={{ color: "red" }}>Quantité</span>
                  </b>
                </label>
                <input
                  className="w3-input w3-border w3-yellow"
                  name="quantite"
                  type="number"
                  value={formData.quantite}
                  style={{ width: "250px" }}
                  required={true}
                  onChange={ajoutQuantite}
                />
              </p>
              <p>
                <label className="w3-text-black">
                  <b style={{ color: "red" }}>Selectionner magasin</b>
                </label>
                &nbsp;
                <select
                  name="magasin"
                  value={formData.magasin}
                  onChange={ajoutQuantite}
                  required
                >
                  <option value="">---</option>
                  {magasin &&
                    magasin.map((x) => (
                      <option key={x.id} value={x.id}>
                        {x.nom}
                      </option>
                    ))}
                </select>
              </p>
              <p>
                <label className="w3-text-black">
                  <b>Details additionels</b>
                </label>
                <input
                  className="w3-input w3-border"
                  name="desc"
                  type="text"
                  value={formData.desc}
                  // style={{ width: "500px" }}
                  required={true}
                  onChange={ajoutQuantite}
                />
              </p>
              <button
                className="w3-btn w3-ripple w3-green w3-round"
                style={{ width: "250px" }}
                name="action"
                type="submit"
              >
                Soumettre
              </button>
            </form>
          </div>
        </div>
      </div>

      <div
        id="retirer"
        class="w3-modal"
        style={{ display: modalRetirer.display }}
      >
        <div class="w3-modal-content" style={{ width: "500px" }}>
          <header class="w3-container w3-orange">
            <span
              onClick={() => setModalRetirer({ display: "none", showing: 0 })}
              class="w3-button w3-display-topright"
            >
              &times;
            </span>
            <h2>Entrez la quantité a retirer</h2>
          </header>
          <div class="w3-container w3-padding">
            <form onSubmit={(e) => handleSubmit(e, "r")}>
              <p>
                <label className="w3-text-black">
                  <b>
                    <span style={{ color: "red" }}>Quantité</span>
                  </b>
                </label>
                <input
                  className="w3-input w3-border w3-yellow"
                  name="quantite"
                  type="number"
                  value={formDataR.quantite}
                  style={{ width: "250px" }}
                  required={true}
                  onChange={retraitQuantite}
                />
              </p>
              <p>
                <label className="w3-text-black">
                  <b style={{ color: "red" }}>Selectionner magasin</b>
                </label>
                &nbsp;
                <select
                  name="magasin"
                  value={formDataR.magasin}
                  onChange={retraitQuantite}
                  required
                >
                  <option value="">---</option>
                  {magasin &&
                    magasin.map((x) => (
                      <option key={x.id} value={x.id}>
                        {x.nom}
                      </option>
                    ))}
                </select>
              </p>
              <p>
                <label className="w3-text-black">
                  <b>Details additionels</b>
                </label>
                <input
                  className="w3-input w3-border"
                  name="desc"
                  type="text"
                  value={formDataR.desc}
                  // style={{ width: "500px" }}
                  required={true}
                  onChange={retraitQuantite}
                />
              </p>
              <button
                className="w3-btn w3-ripple w3-orange w3-round"
                style={{ width: "250px" }}
                name="action"
                type="submit"
              >
                Soumettre
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(StockDetails);
