import { useEffect, useState, useMemo, useRef, useCallback } from "react";
import {
  Link,
  NavLink,
  Outlet,
  useLoaderData,
  useNavigate,
} from "react-router-dom";
import useAPIData from "../../../functions/useAPIData";
// import Table from "../../components/ReactTable"
import { useReactToPrint } from "react-to-print";

export default function ArticleListe() {
  const entity = useLoaderData();
  // .sort((a, b) =>
  //   a["designation"] > b["designation"] ? 1 : -1
  // );

  const classe = useAPIData("classeA");
  const couleur = useAPIData("couleurA");

  // const responsables = useAPIData('employe')

  const toPrint = useRef(null);

  const handlePrint = useReactToPrint({
    documentTitle: "Tous les articles",
    removeAfterPrint: true,
  });

  function getClasse(classeId) {
    const thisClasse = classe.filter((x) => x.id == classeId)[0];

    return thisClasse && thisClasse.classe;
  }

  function getCouleur(couleurId) {
    const thisCouleur = couleur.filter((x) => x.id == couleurId)[0];

    return thisCouleur && thisCouleur.couleur;
  }

  const [listeEntity, setListeEntity] = useState(entity);

  const navigate = useNavigate();

  function selectionEntity(codebarre) {
    navigate("" + codebarre);
  }

  // let sorted = listeEntity;

  // function sortBy(att) {
  //   setListeEntity(sorted.sort((a, b) => (a[att] > b[att] ? 1 : -1)));
  // }
  function search(event) {
    const searchKey = event.target.value.toLowerCase();
    if (searchKey)
      setListeEntity(
        entity.filter((entity) => {
          return (
            (entity && entity.designation.toLowerCase().includes(searchKey)) ||
            (entity && entity.codebarre === searchKey) ||
            (entity && entity.reference.toLowerCase().includes(searchKey)) ||
            (entity &&
              entity.fabriquant &&
              entity.fabriquant.toLowerCase().includes(searchKey))
          );
        })
      );
    else setListeEntity(entity);
  }

  return (
    <div className="repertoire-employe-layout">
      <div className="the-employe-table w3-cell" style={{ width: "100%" }}>
        <button
          onClick={() => {
            handlePrint(null, () => toPrint.current);
          }}
          className="w3-btn w3-ripple w3-pale-blue w3-round w3-margin-bottom"
          style={{ width: "300px" }}
        >
          <i className="fa fa-print" aria-hidden="true"></i> Imprimer
        </button>
        <input
          onChange={search}
          placeholder={
            "Scannez un article ou recherchez article par nom, fabriquant ou reference"
          }
          style={{ width: "100%", backgroundColor: "azure", height: "40px" }}
          autoFocus
        />
        <p></p>
        <div className="toPrint" ref={toPrint}>
          <div className="w3-card-4 w3-bar">
            <table className="w3-table-all w3-hoverable">
              <thead>
                <tr className="w3-light-grey">
                  <th>
                    {/* <button onClick={() => sortBy("designation")}> */}
                    Désignation
                    {/* </button> */}
                  </th>
                  <th>Fabriquant</th>
                  <th>Couleur</th>
                  <th>Catégorie</th>
                  <th>Reference</th>
                </tr>
              </thead>
              <tbody>
                {listeEntity.map((a) => {
                  const classe = getClasse(a.classe);
                  const couleur = getCouleur(a.couleur);

                  return (
                    <tr key={a.id} onClick={() => selectionEntity(a.codebarre)}>
                      <td>{a.designation}</td>
                      <td>{a.fabriquant}</td>
                      <td>{couleur && couleur}</td>
                      <td>{classe && classe}</td>
                      <td>{a.reference}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div
        className="the-employe-details w3-container w3-cell"
        style={{ width: "1000px" }}
      >
        <Outlet />
      </div>
    </div>
  );
}
