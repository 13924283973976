import swal from "sweetalert";

export default function errorBackToAcceuil() {
  swal({
    text: "Vous n'avez pas acces a ce module",
    icon: "error",
    buttons: false,
  });
  setTimeout(function () {
    window.location.replace(process.env.REACT_APP_THE_HOST + "/");
  }, 1500);
}
