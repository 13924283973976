import {
  NavLink,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import generique_employe from "../../../data_et_files/generique_magasin.png";
import useAPIData from "../../../functions/useAPIData";

export default function ArticleDetails() {
  // const loc = useLocation().pathname

  const { id } = useParams();

  const entity = useLoaderData();

  const thisEntity = entity.filter((obj) => obj.id == id)[0];

  const thisResponsable = useAPIData("employe").filter(
    (obj) => obj.numeroEmploye == thisEntity.responsable
  )[0];

  const navigate = useNavigate();

  let display_entity_photo = null;

  if (thisEntity.photo) {
    display_entity_photo = thisEntity.photo.replace("media", "static/media");
  }

  return (
    <div className="theEmployeDetails">
      <button
        onClick={() => navigate("modifier")}
        className="w3-btn w3-ripple w3-yellow w3-round"
        style={{ width: "300px" }}
      >
        <i class="fa fa-pencil" aria-hidden="true"></i> Modifier
      </button>
      {/* <NavLink to="/" className="w3-btn w3-ripple w3-red w3-round w3-margin-left" style={{width:"300px"}}><i class="fa fa-times-circle" aria-hidden="true"></i> Supprimer</NavLink> */}
      <div
        className="w3-panel w3-text-white"
        style={{ backgroundColor: "gray" }}
      >
        <h3>
          Details du magasin: <strong>{thisEntity.nom} </strong>
        </h3>
      </div>
      <div className="w3-card-4 w3-bar">
        <div className="w3-bar-item w3-container w3-cell w3-center w3-padding-16">
          <button
            style={{
              backgroundColor: "white",
              border: "none",
              cursor: "pointer",
            }}
            onClick={() => navigate("modifier-photo")}
          >
            <img
              src={
                display_entity_photo ? display_entity_photo : generique_employe
              }
              className="w3-hide-small w3-border"
              style={{ width: "200px", height: "200px" }}
              alt="#"
            />
            <br />
            Cliquer pour modifier l'image
          </button>
        </div>

        <div className="w3-bar-item w3-container w3-cell">
          <p>
            Nom: <strong>{thisEntity.nom}</strong>
          </p>
          <p>
            Adresse: <strong>{thisEntity.adresse}</strong>
          </p>
          <p>
            Capacite: <strong>{thisEntity.capacite}</strong>
          </p>
          <p>
            Responsable: {thisResponsable && thisResponsable.prenom}{" "}
            {thisResponsable && thisResponsable.nom}
          </p>
          <p>Telephone: {thisEntity.telephone}</p>
          <p>Email: {thisEntity.email}</p>
          <p>Details additionnels: {thisEntity.infoAdd}</p>
        </div>
      </div>
    </div>
  );
}
