import { useEffect, useState } from "react";
import {
  NavLink,
  useLoaderData,
  useNavigate,
  useParams,
} from "react-router-dom";
import useAPIData from "../../../functions/useAPIData";
import sendDevisProduitFormData from "../../../functions/ventes/sendDevisProduitFormData";
import sendDevisFormData from "../../../functions/ventes/sendDevisFormData";
import swal from "sweetalert";

export default function NouvelleTransaction(props) {
  const { numeroDevis } = useParams(); //get parameters

  const entity = useLoaderData(); //devis

  const thisEntity = entity.filter((obj) => obj.numeroDevis == numeroDevis)[0];

  //Load other data sources (from API) as needed

  //this specific projet
  const projet = useAPIData("projet").filter(
    (x) => x.id == thisEntity.projet
  )[0];

  const produit = useAPIData("produit"); //all produits
  const couleur = useAPIData("couleurP"); //all couleurs

  const [devisFormData, setDevisFormData] = useState({ remisePct: 0 }); //useState(thisEntity); //Formdata

  const [produitData, setProduitData] = useState({}); //each produit data

  const [produitList, setProduitList] = useState([]);

  const [txnCount, settxnCount] = useState(1);

  const [montantTotal, setMontantTotal] = useState(0); //montant brut

  const [devisUpdated, setDevisUpdated] = useState(false); //indicator if devis has been updated

  const [produitUpdated, setProduitUpdated] = useState(false); //indicator if article has been updated

  function getCouleur(couleurId) {
    const thisCouleur = couleur.filter((x) => x.id == couleurId)[0];

    return thisCouleur && thisCouleur.couleur;
  }

  function handleChangeA(event) {
    //Article data update
    const { name, value } = event.target;

    setProduitData((prevValues) => ({
      ...prevValues,
      [name]: value,
      devis: numeroDevis,
    }));
  }

  function handleChangeD(event) {
    //Devis form update
    const { name, value } = event.target;

    setDevisFormData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setDevisUpdated(false);
  }

  async function additionalData(produitId, largeur, hauteur, quantite) {
    //get this article specifically
    const thisProduit = produit.filter((obj) => obj.id == produitId)[0];
    const thisCouleur =
      thisProduit && couleur.filter((obj) => obj.id == thisProduit.couleur)[0];

    //get the fields needed
    const reference = thisProduit && thisProduit.reference;

    const classe = thisProduit && thisProduit.classe;

    const designation = thisProduit && thisProduit.designation;

    const prix = thisProduit && thisProduit.prix;

    const couleur_ = thisCouleur && thisCouleur.couleur;

    const dimension_ = largeur + "x" + hauteur;

    // const dimension_ =
    //   thisDimension && thisDimension.largeur + "x" + thisDimension.hauteur;

    // const largeur = thisDimension && thisDimension.largeur;

    // const hauteur = thisDimension && thisDimension.hauteur;

    // const photo = ... // add in version 2

    let montant_ = thisProduit && Number(thisProduit.prix) * Number(quantite);
    let prixUnitaire = thisProduit && Number(thisProduit.prix);

    if (largeur > 0 && hauteur > 0) {
      montant_ = montant_ * (((largeur / 1000) * hauteur) / 1000);
      prixUnitaire = prixUnitaire * (((largeur / 1000) * hauteur) / 1000);
    }

    //consolidate and return the data
    const data = {
      reference: reference,
      classe: classe,
      numero: txnCount,
      designation: designation,
      prix: prix,
      prixUnitaire: prixUnitaire,
      couleur_: couleur_,
      dimension_: dimension_,
      montant: montant_,
    };

    return data;
  }

  function add(event) {
    event.preventDefault();

    settxnCount((prevValues) => {
      return prevValues + 1;
    });

    additionalData(
      produitData.produit,
      produitData.largeur,
      produitData.hauteur,
      produitData.quantite
    )
      .then((data) =>
        setProduitData((prevValues) => ({ ...prevValues, ...data }))
      )
      .then(() => setProduitUpdated(true));
    setDevisUpdated(false);
  }

  if (produitUpdated) {
    setProduitList([...produitList, produitData]);
    setProduitUpdated(false);
  }

  function remove(index) {
    let produitAfterIndex = produitList.filter((x, i) => i > index);
    let produitBeforeIndex = produitList.filter((x, i) => i < index);

    produitAfterIndex.map((x) => x.numero--);

    setProduitList(produitBeforeIndex.concat(produitAfterIndex));
    settxnCount((prevValues) => prevValues - 1);
    setDevisUpdated(false);
  }

  function updateDevis() {
    setMontantTotal(
      produitList.reduce((a, { montant }) => Number(a) + Number(montant), 0)
    );
    setDevisFormData((prevValues) => ({
      ...prevValues,
      montantBrut: produitList.reduce(
        (a, { montant }) => Number(a) + Number(montant),
        0
      ),
      montantRemise:
        (produitList.reduce(
          (a, { montant }) => Number(a) + Number(montant),
          0
        ) *
          devisFormData.remisePct) /
        100,
      montantNet:
        produitList.reduce((a, { montant }) => Number(a) + Number(montant), 0) -
        (produitList.reduce(
          (a, { montant }) => Number(a) + Number(montant),
          0
        ) *
          devisFormData.remisePct) /
          100,
    }));
    setDevisUpdated(true);
  }

  async function handleSubmit() {
    const sendProduitData = () => {
      produitList.map((x, i) => {
        sendDevisProduitFormData(
          x,
          process.env.REACT_APP_THE_HOST + "/api/devisProduit/"
        );
      });
    };

    await sendProduitData();

    await sendDevisFormData(
      devisFormData,
      process.env.REACT_APP_THE_HOST + "/api/devis/" + numeroDevis + "/",
      "PATCH",
      "existing"
    );
  }

  return (
    <div id="repertoire-employe-layout" className="w3-row">
      <div id="devis-produit-form" className="w3-half w3-animate-left">
        <div className="w3-panel w3-indigo w3-round">
          <h3>Veuillez enumerer les transactions</h3>
        </div>
        <div id="ajouter-produit">
          <form method="post" onSubmit={add}>
            <p className="w3-center w3-border w3-light-gray">
              <label className="w3-text-black w3-margin-right">
                <b>Transaction No. {txnCount}</b>
              </label>
            </p>
            <div className="w3-border w3-padding">
              <p>
                <label className="w3-text-black">
                  <b>Date: </b>
                </label>
                <input
                  name="date"
                  type="date"
                  value={produitData.date}
                  onChange={handleChangeA}
                  required={true}
                  style={{ width: 350 }}
                />{" "}
                &nbsp;
                <label className="w3-text-black w3-margin-right">
                  <b>Caisse: </b>
                </label>
                <select
                  name="produit"
                  value={produitData.produit}
                  onChange={handleChangeA}
                  required
                  style={{ width: 350 }}
                >
                  <option value={""}>---</option>
                  {produit.map((x) => {
                    const c = getCouleur(x.couleur);
                    return (
                      <option key={x.id} value={x.id}>
                        {x.designation} ({c})
                      </option>
                    );
                  })}
                </select>{" "}
              </p>
              <p>
                <label className="w3-text-black w3-margin-right">
                  <b>Type txn: </b>
                </label>
                <select
                  name="produit"
                  value={produitData.produit}
                  onChange={handleChangeA}
                  required
                  style={{ width: 300 }}
                >
                  <option value={""}>---</option>
                  {produit.map((x) => {
                    const c = getCouleur(x.couleur);
                    return (
                      <option key={x.id} value={x.id}>
                        {x.designation} ({c})
                      </option>
                    );
                  })}
                </select>{" "}
                &nbsp;
                <label className="w3-text-black w3-margin-right">
                  <b>Categorie txn: </b>
                </label>
                <select
                  name="produit"
                  value={produitData.produit}
                  onChange={handleChangeA}
                  required
                  style={{ width: 290 }}
                >
                  <option value={""}>---</option>
                  {produit.map((x) => {
                    const c = getCouleur(x.couleur);
                    return (
                      <option key={x.id} value={x.id}>
                        {x.designation} ({c})
                      </option>
                    );
                  })}
                </select>
              </p>
              <p></p>
              <p>
                <label className="w3-text-black w3-margin-right">
                  <b>Libelle:</b>
                </label>
                <input
                  className="w3-input w3-border w3-cell"
                  name="section"
                  type="text"
                  value={produitData.section}
                  style={{ width: 765 }}
                  onChange={handleChangeA}
                />
              </p>
              <p>
                <label className="w3-text-black w3-margin-right">
                  <b>Montant:</b>
                </label>
                <input
                  className="w3-input w3-border w3-cell"
                  name="section"
                  type="text"
                  value={produitData.section}
                  style={{ width: 300 }}
                  onChange={handleChangeA}
                />
              </p>
              <p>
                <label className="w3-text-black w3-margin-right">
                  <b>Observations:</b>
                </label>
                <input
                  className="w3-input w3-border w3-cell"
                  name="section"
                  type="text"
                  value={produitData.section}
                  style={{ width: 710 }}
                  onChange={handleChangeA}
                />
              </p>
            </div>

            <button
              className="w3-btn w3-ripple w3-blue w3-round-xxlarge w3-margin-top"
              style={{ width: "200px" }}
              type="submit"
            >
              <i className="fa fa-plus-circle"></i> ajouter
            </button>
          </form>
        </div>
      </div>
      <div id="devis-produit-view" className="w3-half">
        <div
          id="devis-heading"
          className="w3-panel w3-text-white"
          style={{ backgroundColor: "gray" }}
        >
          <h3>
            Devis No: <strong>{numeroDevis}</strong>
          </h3>
          <p>
            Date{" "}
            <strong>{thisEntity && thisEntity.date.substring(0, 10)}</strong>
          </p>
          <p>
            Projet: <strong>{projet && projet.nom}</strong>
          </p>
          <p>
            Client:{" "}
            <strong>
              {projet &&
                projet.clientPrenom +
                  " " +
                  projet.clientNom +
                  "/ " +
                  projet.clientEntreprise}
            </strong>
          </p>
          <p>
            Agent commercial:{" "}
            <strong>{thisEntity && thisEntity.agentCommercial}</strong>
          </p>
        </div>
        <hr />

        <div id="devis-content" className="w3-white w3-margin-top">
          <table class="w3-table w3-bordered">
            <thead>
              <tr>
                <th>No.</th>
                <th>Section</th>
                <th>Designation</th>
                <th>Qte</th>
                <th>Pu</th>
                <th>Montant</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {produitList.map((x, i) => (
                <tr>
                  <td>{x.numero}</td>
                  <td>{x.section}</td>
                  <td>
                    {x.designation} ({x.couleur_}) ({x.dimension_})
                  </td>
                  <td>{x.quantite}</td>
                  <td>{x.prixUnitaire}</td>
                  <td>{x.montant}</td>
                  <td>
                    <button className="w3-btn w3-red" onClick={() => remove(i)}>
                      enlever
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* <div style={{ display: showSummary }}>
         */}
        <div>
          <hr />
          {/* <h3 className="w3-yellow w3-round w3-padding">
            Montant total = {montantTotal}
          </h3> */}
          <div id="devis-form">
            <form method="post">
              <h4>
                <label className="w3-text-black w3-margin-right">
                  Montant total hors TVA
                </label>
                <input
                  className="w3-input w3-border w3-cell w3-grey"
                  name="montantBrut"
                  value={montantTotal}
                  style={{ width: "300px" }}
                  readOnly
                />
              </h4>
              <h4>
                <label className="w3-text-black w3-margin-right">
                  Remise en %
                </label>
                <input
                  className="w3-input w3-border w3-cell"
                  name="remisePct"
                  value={devisFormData.remisePct}
                  style={{ width: "300px" }}
                  placeholder="Eg. 15 pour 15%"
                  onChange={handleChangeD}
                />
              </h4>
              <h3>
                <label className="w3-text-black w3-margin-right">
                  Montant total net hors TVA
                </label>
                <input
                  className="w3-input w3-border w3-cell w3-light-blue"
                  name="montantNet"
                  value={devisFormData.montantNet}
                  style={{ width: "300px" }}
                  readOnly
                />
              </h3>
            </form>
            <hr />
            <button
              className="w3-btn w3-ripple w3-purple w3-round w3-margin-top"
              style={{ width: "200px" }}
              onClick={() => updateDevis()}
            >
              Rafraichir montants
            </button>
            <button
              className="w3-btn w3-ripple w3-green w3-round w3-margin-top w3-margin-left"
              style={{ width: "300px", display: !devisUpdated ? "none" : "" }}
              onClick={() => handleSubmit()}
            >
              Soumettre
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
