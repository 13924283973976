import { Link, NavLink } from "react-router-dom";

export default function SidebarFixed() {
  return (
    <div
      class="w3-sidebar w3-bar-block w3-card w3-padding-large"
      style={{ width: "15%" }}
    >
      <h4 class="w3-bar-item w3-border">Selectionnez un rapport</h4>
      <p>
        <Link
          class="w3-bar-item w3-button w3-green w3-round"
          to={"/analytics/stock"}
        >
          Stock
        </Link>
      </p>
      {/* <p>
        <Link
          class="w3-bar-item w3-button w3-green w3-round"
          to={"/analytics/stock-txns"}
        >
          Entrees/sorties stock
        </Link>
      </p> */}
      <p>
        <Link
          class="w3-bar-item w3-button w3-green w3-round"
          to={"/analytics/personnel"}
        >
          Personnel
        </Link>
      </p>
    </div>
  );
}
