import { NavLink } from "react-router-dom";
import MenuButton from "../../components/MenuButton";

export default function OperationsHome() {
  return (
    <div className="personnel-page">
      <div className="w3-row">
        <div className="w3-container">
          <header class="w3-container w3-theme w3-padding" id="myHeader">
            <div class="w3-center">
              <p class="w3-xlarge w3-animate-bottom">
                Veuillez selectionner parmi les options suivantes
              </p>
            </div>
          </header>

          <div class="w3-row-padding w3-center w3-margin-top">
            <MenuButton
              title={"Stock"}
              desc={"Consulter, approvisionner et retirer le stock"}
              icon={"fa fa-database"}
              // w3color={"w3-deep-orange"}
              link={"stock"}
            />

            <MenuButton
              title={"Articles bruts"}
              desc={"Consulter, ajouter, modifier et supprimer articles"}
              icon={"fa fa-table"}
              // w3color={"w3-deep-orange"}
              link={"articles"}
            />

            <MenuButton
              title={"Magasins"}
              desc={"Consulter, ajouter, modifier et supprimer magasins"}
              icon={"fa fa-home"}
              // w3color={"w3-deep-orange"}
              link={"magasins"}
            />

            <MenuButton
              title={"Produits finis"}
              desc={"Consulter, ajouter, modifier et supprimer produits"}
              icon={"fa fa-table"}
              // w3color={"w3-deep-orange"}
              link={"produits"}
              addToClass={"w3-margin-top"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
