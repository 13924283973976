import {
  NavLink,
  Navigate,
  useLoaderData,
  useLocation,
  useParams,
} from "react-router-dom";
import useAPIData from "../../../functions/useAPIData";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
export default function ProjetDetails() {
  const { id } = useParams();

  const entity = useLoaderData();

  const thisEntity = entity.filter((obj) => obj.id == id)[0];

  const goTo_modifier_info = useLocation().pathname.replace(
    "details",
    "modifier"
  );

  const toPrint = useRef(null);

  const handlePrint = useReactToPrint({
    documentTitle: "Details du projet " + thisEntity.nom,
    removeAfterPrint: true,
  });

  const devis = useAPIData("devis").filter((obj) => obj.projet == id);

  return (
    <div className="theEmployeDetails">
      <button
        onClick={() => {
          handlePrint(null, () => toPrint.current);
        }}
        className="w3-btn w3-ripple w3-pale-blue w3-round w3-margin-right"
        style={{ width: "300px" }}
      >
        <i className="fa fa-print" aria-hidden="true"></i> Imprimer
      </button>
      <NavLink
        to={goTo_modifier_info}
        className="w3-btn w3-ripple w3-yellow w3-round"
        style={{ width: "300px" }}
      >
        <i class="fa fa-pencil" aria-hidden="true"></i> Modifier
      </NavLink>
      {/* <NavLink
        to="/"
        className="w3-btn w3-ripple w3-red w3-round w3-margin-left"
        style={{ width: "300px" }}
      >
        <i class="fa fa-times-circle" aria-hidden="true"></i> Supprimer
      </NavLink> */}
      <NavLink
        to={"/ventes/devis/nouveau-devis/" + thisEntity.id}
        className="w3-btn w3-ripple w3-purple w3-round w3-margin-left"
        style={{ width: "300px" }}
      >
        <i class="fa fa-table" aria-hidden="true"></i> Creer devis
      </NavLink>
      <div className="toPrint" ref={toPrint}>
        <div
          className="w3-panel w3-text-white"
          style={{ backgroundColor: "gray" }}
        >
          <h3>
            Information sur le projet <strong>No.{thisEntity.id}</strong>:{" "}
            <strong>{thisEntity.nom}</strong>
          </h3>
        </div>
        <div className="w3-row">
          <div className="w3-half">
            <div className="w3-card-4 w3-bar">
              <div className="w3-bar-item w3-container w3-cell">
                <p>
                  Numero de projet: <strong>{thisEntity.id}</strong>
                </p>
                <p>
                  Nom du projet: <strong>{thisEntity.nom}</strong>
                </p>
                <p>
                  Client:{" "}
                  <strong>
                    <NavLink
                      to={
                        process.env.REACT_APP_THE_HOST +
                        "/ventes/clients/" +
                        thisEntity.client +
                        "/details"
                      }
                      style={{ color: "blue" }}
                    >
                      {thisEntity.clientPrenom +
                        " " +
                        thisEntity.clientNom +
                        "/ " +
                        thisEntity.clientEntreprise}
                    </NavLink>
                  </strong>
                </p>

                <p>
                  Responsable Vial-Mali:{" "}
                  <strong>
                    <NavLink
                      to={
                        process.env.REACT_APP_THE_HOST +
                        "/personnel/employes/" +
                        thisEntity.responsable +
                        "/details"
                      }
                      style={{ color: "blue" }}
                    >
                      {thisEntity.responsablePrenom +
                        " " +
                        thisEntity.responsableNom}
                    </NavLink>
                  </strong>
                </p>
                <p>
                  Date de debut: <strong>{thisEntity.dateDebut}</strong>
                </p>
                <p>
                  Date de fin: <strong>{thisEntity.dateFin}</strong>
                </p>
                <p>
                  Duree (en jour): <strong>{thisEntity.duree}</strong>
                </p>
                <p>Details: {thisEntity.details}</p>
              </div>
            </div>
          </div>
          <div className="w3-third w3-margin-left">
            <p>
              <strong>Devis relies a ce projet</strong>
            </p>
            <ul>
              {devis &&
                devis.map((x) => (
                  <li>
                    <NavLink
                      to={
                        process.env.REACT_APP_THE_HOST +
                        "/ventes/devis/" +
                        x.numeroDevis +
                        "/details"
                      }
                      style={{ color: "blue" }}
                    >
                      {x.numeroDevis} {x.date} {x.montantBrut}
                    </NavLink>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
