import { useEffect, useState, useMemo } from "react";
import {
  Link,
  NavLink,
  Outlet,
  useLoaderData,
  useNavigate,
} from "react-router-dom";
// import Table from "../../components/ReactTable"

export default function ProjetLayout() {
  return (
    <div className="repertoire-employes-layout">
      <div className="w3-pannel w3-gray">
        <div className="w3-bar">
          <h3 className="w3-bar-item w3-text-white">
            <strong>
              Projets <i class="fa fa-briefcase" aria-hidden="true" />
            </strong>
          </h3>
          {/* <button
            onClick={() =>
              window.location.replace(
                process.env.REACT_APP_THE_HOST +
                  "/ventes/projets/nouveau-projet/0"
              )
            }
            id="theNavLinkMenu"
            className="w3-bar-item w3-button w3-ripple w3-red w3-round w3-margin-left w3-margin-top"
          >
            <i className="fa fa-plus-circle" aria-hidden="true" /> Créer nouveau
            projet
          </button> */}
          <NavLink
            to=""
            id="theNavLinkMenu"
            className="w3-bar-item w3-button w3-ripple w3-light-gray w3-round w3-margin-left w3-margin-top"
          >
            <i className="fa fa-book" aria-hidden="true" /> Repertoire des
            projets
          </NavLink>

          <NavLink
            to="/ventes"
            id="theNavLinkMenu"
            className="w3-bar-item w3-button w3-ripple w3-grey w3-round w3-margin-left w3-margin-top"
          >
            <i className="fa fa-chevron-circle-left" aria-hidden="true" />{" "}
            Retour au menu Gestion des ventes
          </NavLink>
        </div>
      </div>
      <div className="w3-container w3-padding">
        <div className="the-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
