import {useState} from 'react'
import {Navigate, useParams, NavLink} from "react-router-dom"
import {connect} from "react-redux"
import { verify } from '../actions/auth'
import swal from 'sweetalert'

const Activate = ({ verify }) => {

    const {uid,token} = useParams()

    const[verified,setVerified] = useState(false)

    const verify_account = e => {
        e.preventDefault()

        verify(uid,token)
        setVerified(true)
    }

    // Is the user authenticated?
    //Redirect to home page
    if (verified){
        swal({
            text:"Votre compte est active!",
            icon:"success",
            buttons:false
        })

        setTimeout(function(){
            window.location.replace('/login')
        }, 1500)
    }

    return(
        <div className="acceuil">
        <div className="w3-bar w3-blue w3-center" style={{height:"52px"}}>
        <NavLink to="/" style={{ textDecoration: 'none' }}><h3>Vial-Mali Admin <i class="fa fa-home" aria-hidden="true"/></h3></NavLink>        </div>
        <div className='w3-container w3-center w3-padding'>

            <div className="w3-container margin-top">
            <div className='d-flex flex-column justify-content align-items-center' style={{marginTop:'200px'}}>
                <h1>Verifier votre compte:</h1>
                <button className='w3-button w3-blue' onClick={verify_account} style={{marginTop:"200px"}}>
                    Verifier
                </button>
            </div>
        </div>
        </div>
        </div>
    )
}

export default connect(null,{ verify }) (Activate);