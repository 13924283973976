import { useState } from "react";
import {
  Form,
  Link,
  NavLink,
  Outlet,
  redirect,
  useActionData,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import useAPIData from "../../../functions/useAPIData";
import sendArticleFormData from "../../../functions/operations/sendArticleFormData";
import { connect } from "react-redux";
import LoadingSpinner from "../../../components/LoadingSpinner";
import errorBackToAcceuil from "../../../functions/errorBackToAcceuil";

function ArticleForm({ user, new_ind }) {
  const { codebarre } = useParams(); //get parameters

  const entity = useLoaderData(); //articles

  const thisEntity = entity.filter((obj) => obj.codebarre == codebarre)[0];

  //Load other data sources (from API) as needed
  const classe = useAPIData("classeA");

  const couleur = useAPIData("couleurA");

  const unite = useAPIData("unite");

  //Variables for handling form
  let initValue = {};
  if (new_ind == "N") {
    initValue = thisEntity;
  } else {
    initValue = {
      reference: "VMXX0000",
    };
  }

  const [formData, setFormData] = useState(initValue);

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData((prevValues) => ({ ...prevValues, [name]: value }));
  }

  // <img class="barcode"
  // jsbarcode-format="ean13"
  // jsbarcode-value={thisEntity.codebarre}
  // jsbarcode-textmargin="0"
  // jsbarcode-fontoptions="bold"
  // />

  function handleSubmit(event) {
    event.preventDefault();

    // Conditional handling of the form
    if (new_ind == "Y") {
      // If new object
      sendArticleFormData(
        formData,
        process.env.REACT_APP_THE_HOST + "/api/article/",
        "POST",
        "new"
      );
    } else if (new_ind == "N") {
      // If existing object
      sendArticleFormData(
        formData,
        process.env.REACT_APP_THE_HOST + "/api/article/" + formData.id + "/",
        "PATCH",
        "existing"
      );
    }
  }

  return !user ? (
    <LoadingSpinner color="blue" show={true} />
  ) : user.accessOperations < 3 ? (
    errorBackToAcceuil()
  ) : (
    <div className="repertoire-employe-layout">
      <div style={{ width: "800px" }}>
        {/* The selection content */}

        <form method="post" onSubmit={handleSubmit}>
          <div
            className="w3-panel w3-text-white"
            style={{ backgroundColor: "gray" }}
          >
            <h3>
              Veuillez fournir les informations ci-dessous{" "}
              <span style={{ color: "red" }}>(requis)</span>
            </h3>
          </div>
          <p>
            <label className="w3-text-black">
              <b>
                <span style={{ color: "red" }}>Designation</span>
              </b>
            </label>
            <input
              className="w3-input w3-border w3-sand"
              name="designation"
              type="text"
              value={formData.designation}
              onChange={handleChange}
              required
            />
          </p>
          <p>
            <p>
              <label className="w3-text-black">
                <b>
                  <span style={{ color: "red" }}>Categorie: </span>
                </b>
              </label>
              &nbsp;
              <select
                name="classe"
                value={formData.classe}
                onChange={handleChange}
                required
              >
                <option disabled value={""}>
                  selectionner categorie
                </option>
                {classe.map((x) => (
                  <option key={x.id} value={x.id}>
                    {x.classe}
                  </option>
                ))}
              </select>
            </p>
            <p>
              <label className="w3-text-black">
                <b>
                  <span style={{ color: "red" }}>
                    Fabriquant (si necessaire)
                  </span>
                </b>
              </label>
              <input
                className="w3-input w3-border w3-sand"
                name="fabriquant"
                type="text"
                value={formData.fabriquant}
                onChange={handleChange}
              />
            </p>

            <p>
              <label className="w3-text-black">
                <b>
                  <span style={{ color: "red" }}>Couleur: </span>
                </b>
              </label>
              &nbsp;
              <select
                name="couleur"
                value={formData.couleur}
                onChange={handleChange}
                required
              >
                <option value={""}>selectionner couleur</option>
                {couleur.map((x) => (
                  <option key={x.id} value={x.id}>
                    {x.couleur}
                  </option>
                ))}
              </select>
            </p>

            <p>
              <label className="w3-text-black">
                <b>
                  <span style={{ color: "red" }}>Unite: </span>
                </b>
              </label>
              &nbsp;
              <select
                name="unite"
                value={formData.unite}
                onChange={handleChange}
                required
              >
                <option value={""}>selectionner unite</option>
                {unite.map((x) => (
                  <option key={x.id} value={x.id}>
                    {x.unite}
                  </option>
                ))}
              </select>
            </p>

            <p>
              <label className="w3-text-black">
                <b>
                  <span style={{ color: "red" }}>Reference: </span>
                </b>
              </label>
              <input
                className="w3-input w3-border w3-sand"
                name="reference"
                type="text"
                value={formData.reference}
                onChange={handleChange}
                required
              />
            </p>

            <label className="w3-text-black">
              <b>
                <span>Details additionnels</span>
              </b>
            </label>
            <textarea
              className="w3-input w3-border w3-sand"
              name="infoAdd"
              type="text"
              value={formData.infoAdd}
              onChange={handleChange}
            />
          </p>

          <button
            className="w3-btn w3-ripple w3-green w3-round"
            style={{ width: "500px" }}
            name="action"
            type="submit"
          >
            Soumettre
          </button>
          <button
            className="w3-btn w3-ripple w3-light-grey w3-round w3-margin-left"
            style={{ width: "284px" }}
            onClick={() =>
              new_ind == "Y"
                ? window.location.replace(
                    process.env.REACT_APP_THE_HOST + "/operations/articles"
                  )
                : window.location.replace(
                    process.env.REACT_APP_THE_HOST +
                      "/operations/articles/" +
                      codebarre +
                      "/details"
                  )
            }
          >
            Annuler
          </button>
        </form>
        <p></p>

        <p></p>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(ArticleForm);
