import { BeatLoader } from "react-spinners";

function LoadingSpinner({ show, color }) {
  return (
    <div class="w3-modal" style={{ display: show ? "block" : "none" }}>
      <div
        class="w3-modal-content w3-display-container w3-round-large"
        style={{ width: "250px", height: "250px" }}
      >
        <BeatLoader className="w3-display-middle" color={color} />
      </div>
    </div>
  );
}

export default LoadingSpinner;
