import { connect } from "react-redux";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { NavLink, useLoaderData } from "react-router-dom";
import { useState } from "react";
import swal from "sweetalert";
import sendTransactionStockFormData from "../../../functions/operations/sendTransactionStockFormData";
import useAPIData from "../../../functions/useAPIData";

function StockRetirer({ user }) {
  const articleStocks = useLoaderData();
  const [stocks, setStocks] = useState(null);
  const [display, setDisplay] = useState("none");
  const [formData, setFormData] = useState({
    indApprovision: "R",
    // utilisateur: user && user.employeNumber,
  });

  const [articleId, setArticleId] = useState(null);
  const articles = useAPIData("article");
  const [photo, setPhoto] = useState(null);

  function search(event) {
    const code = event.target.value;
    if (code) {
      if (articleStocks.filter((a) => a.codebarre == code).length > 0) {
        setDisplay("block");
        setStocks(articleStocks.filter((a) => a.codebarre == code));
        setFormData((prevValues) => ({
          ...prevValues,
          article: articleStocks
            .filter((a) => a.codebarre == code)[0]
            .article.toString(),
          magasin: articleStocks
            .filter((a) => a.codebarre == code)[0]
            .magasin.toString(),
        }));
        setArticleId(
          articleStocks.filter((a) => a.codebarre == code)[0].article
        );
        setPhoto(
          articles &&
            articles
              .filter((a) => a.codebarre == code)[0]
              .photo.replace("media", "static/media")
        );
      } else {
        setDisplay("none");
        setStocks(null);
      }
    }
  }
  function handleChange(event) {
    const { name, value } = event.target;
    setFormData((prevValues) => ({ ...prevValues, [name]: value }));
  }

  function handleSubmit(event) {
    event.preventDefault();
    const s = articleStocks.filter(
      (obj) =>
        obj.article == formData.article &&
        obj.magasin == formData.magasin &&
        obj.unite == formData.unite
    )[0];

    if (!s) {
      swal({
        text: "Quantité en stock insuffisante pour cette transaction!",
        icon: "error",
        buttons: false,
      });
      setTimeout(function () {
        window.location.replace(
          process.env.REACT_APP_THE_HOST +
            "/operations/stock/" +
            articleId +
            "/details"
        );
      }, 1500);
    } else if (formData.quantite > s.quantiteCourante) {
      swal({
        text: "Quantité en stock insuffisante pour cette transaction!",
        icon: "error",
        buttons: false,
      });
      setTimeout(function () {
        window.location.replace(
          process.env.REACT_APP_THE_HOST +
            "/operations/stock/" +
            articleId +
            "/details"
        );
      }, 1500);
    } else
      sendTransactionStockFormData(
        {
          utilisateur: user && user.employeNumber,
          ...formData,
        },
        process.env.REACT_APP_THE_HOST + "/api/transactionStock/"
      );
  }

  return !user ? (
    <LoadingSpinner color="blue" show={true} />
  ) : (
    <div>
      <h2>
        <b className="w3-text-orange">Retrait du stock</b>
      </h2>
      <p>
        <input
          onChange={search}
          placeholder={"Scannez un code barre"}
          style={{ width: "100%", height: "40px" }}
          className="w3-round w3-border-orange"
          autoFocus
        />
      </p>
      <hr></hr>
      {/* <div className="w3-bar" style={{ display: display }}> */}
      <div style={{ display: display }}>
        {/* <div className="w3-bar-item" style={{ width: "100%" }}> */}
        <div>
          {stocks && (
            <div>
              <form
                class="w3-border w3-padding w3-round"
                // style={{ width: "50%" }}
                onSubmit={(e) => handleSubmit(e)}
              >
                <h3>
                  <b>
                    {stocks[0].designation +
                      " (" +
                      stocks[0].couleur +
                      ") - " +
                      stocks[0].fabriquant}
                  </b>
                </h3>
                <hr />
                <div class="w3-row">
                  <div class="w3-col w3-center" style={{ width: "30%" }}>
                    <b>Magasin</b>
                  </div>
                  <select
                    // style={{ width: "70%" }}
                    name="magasin"
                    value={formData.magasin}
                    onChange={handleChange}
                    // required
                  >
                    <option value={stocks[0].magasin} selected="selected">
                      {stocks[0].magasin_display}
                    </option>
                    {stocks.map(
                      (x) =>
                        x.magasin !== stocks[0].magasin && (
                          <option key={x.magasin} value={x.magasin}>
                            {x.magasin_display}
                          </option>
                        )
                    )}
                  </select>
                </div>
                <div class="w3-row w3-border w3-margin-top">
                  <div class="w3-col w3-center" style={{ width: "30%" }}>
                    <h3>
                      <b>Quantité à retirer</b>
                    </h3>
                  </div>
                  <div class="w3-rest">
                    <input
                      className=" w3-xxlarge w3-border w3-orange"
                      name="quantite"
                      type="number"
                      value={formData.quantite}
                      required={true}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div class="w3-row w3-border w3-margin-top">
                  <div class="w3-col w3-center" style={{ width: "30%" }}>
                    <h3>
                      <b>Details</b>
                    </h3>
                  </div>
                  <div class="w3-rest">
                    <input
                      className=" w3-xxlarge w3-border w3-sand"
                      name="desc"
                      type="text"
                      value={formData.desc}
                      required={true}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <button
                  className="w3-btn w3-ripple w3-xxlarge w3-green w3-round w3-margin-top"
                  style={{ width: "400px" }}
                  name="action"
                  type="submit"
                >
                  OK
                </button>
              </form>
              <div class="w3-padding">
                <NavLink
                  className="w3-btn w3-ripple w3-red w3-xlarge w3-round"
                  style={{ width: "400px" }}
                  to="/operations/stock/"
                >
                  Annuler
                </NavLink>
                <div className="w3-margin-top">
                  <img
                    src={photo}
                    style={{ width: "400px", height: "400px" }}
                    alt="#"
                    className="w3-border w3-padding"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {/* <div className="w3-bar-item w3-border"> */}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(StockRetirer);
