import { createContext, useReducer } from "react";

export const DevisArticlesContext = createContext();

export const devisArticlesReducer = (state, action) => {
  switch (action.type) {
    // case 'SET_DEVISARTICLES':
    //     return {
    //         devisArticles: action.payload
    //     }
    case "ADD_ARTICLE":
      return {
        devisArticles: [action.payload, ...state.devisArticles],
      };
    default:
      return state;
  }
};

export const DevisArticlesContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(devisArticlesReducer, {
    devisArticles: null,
  });

  return (
    <DevisArticlesContext.Provider value={{ ...state, dispatch }}>
      {children}
    </DevisArticlesContext.Provider>
  );
};
