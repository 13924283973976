import { NavLink } from "react-router-dom";
// import logo from "../../data_et_files/vial_mali_logo.png"
import { connect } from "react-redux";
import { logout } from "../../authsys/actions/auth";
import Home from "../../authsys/containers/Home";
import MenuButton from "../../components/MenuButton";
import vial_mali_logo from "../../data_et_files/vial_mali_logo.png";

function Acceuil({ logout, isAuthenticated, user }) {
  return (
    <div className="acceuil">
      <div
        className="w3-container-display w3-bar w3-blue w3-center"
        style={{ height: "52px" }}
      >
        <div style={{ textDecoration: "none" }}>
          <h3>
            <img
              src={vial_mali_logo}
              height={30}
              width={45}
              style={{ marginTop: "-7px" }}
            />
            &nbsp; SGVM
          </h3>
        </div>
        <NavLink
          onClick={isAuthenticated ? logout : ""}
          id="theNavLinkModule"
          style={{ textDecoration: "none" }}
          className="w3-bar-item w3-button w3-red w3-xlarge w3-display-topright"
        >
          {isAuthenticated ? (
            <span>
              <i class="fa fa-sign-out" aria-hidden="true" />
              Se deconnecter
            </span>
          ) : (
            <span>
              <i class="fa fa-times-circle" aria-hidden="true" />
              Pas connecte
            </span>
          )}
        </NavLink>
      </div>

      {isAuthenticated ? (
        <div class="w3-row-padding w3-center w3-margin-top">
          <header class="w3-container w3-theme w3-padding" id="myHeader">
            <div class="w3-center">
              <p class="w3-xlarge w3-animate-bottom">
                Bienvenue dans le <span style={{ color: "blue" }}>SGVM</span>,
                le Systeme de Gestion de la Vial-Mali
              </p>
              <p class="w3-large w3-animate-bottom">
                Veuillez sélectionner parmi les modules suivants
              </p>
            </div>
          </header>
          {/* {user && user.accessVentes > 0 ? (
            <MenuButton
              title={"Commercial"}
              desc={"Création de devis, gestion des clients et des projets"}
              icon={"fa fa-money"}
              w3color={"w3-blue"}
              link={"ventes"}
            />
          ) : (
            ""
          )} */}

          {user && user.accessOperations > 0 ? (
            <MenuButton
              title={"Opérations"}
              desc={"Gestion du stock, des articles et des magasins"}
              icon={"fa fa-cog"}
              w3color={"w3-red"}
              link={"operations"}
            />
          ) : (
            ""
          )}
          {user && user.accessPersonnel > 0 ? (
            <MenuButton
              title={"Personnel"}
              desc={"Gestion des employés, des absences et des salaires"}
              icon={"fa fa-users"}
              w3color={"w3-purple"}
              link={"personnel"}
            />
          ) : (
            ""
          )}

          {/* {user && user.accessVentes > 0 ? (
            <MenuButton
              title={"Finances"}
              desc={"Gestion des finances - Caisses et Banques"}
              icon={"fa fa-money"}
              w3color={"w3-indigo"}
              link={"finances"}
              addToClass={"w3-margin-top"}
            />
          ) : (
            ""
          )} */}

          {user && user.accessVentes > 0 ? (
            <MenuButton
              title={"Rapports et BI"}
              desc={
                "BI (intelligence d'affaires) - graphiques, rapports et analytique"
              }
              icon={"fa fa-bar-chart"}
              w3color={"w3-teal"}
              link={"analytics"}
              // addToClass={"w3-margin-top"}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        <Home />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps, { logout })(Acceuil);
