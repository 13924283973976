// import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

//AUTH SYSTEM
import Login from "./authsys/containers/Login";
import Signup from "./authsys/containers/Signup";
import Activate from "./authsys/containers/Activate";
import ResetPassword from "./authsys/containers/ResetPassword";
import ResetPasswordConfirm from "./authsys/containers/ResetPasswordConfirm";

import { Provider } from "react-redux";
import store from "./store";

//APPLICATION
//layouts
import RootLayout from "./layouts/RootLayout";

import VentesLayout from "./layouts/ventes/VentesLayout";
import DevisLayout from "./layouts/ventes/DevisLayout";
import ProjetLayout from "./layouts/ventes/ProjetLayout";
import ClientLayout from "./layouts/ventes/ClientLayout";
import TransactionLayout from "./layouts/ventes/TransactionLayout";

import OperationsLayout from "./layouts/operations/OperationsLayout";
import StockLayout from "./layouts/operations/StockLayout";
import ArticleLayout from "./layouts/operations/ArticleLayout";
import ProduitLayout from "./layouts/operations/ProduitLayout";
import MagasinLayout from "./layouts/operations/MagasinLayout";

import PersonnelLayout from "./layouts/personnel/PersonnelLayout";
import EmployeLayout from "./layouts/personnel/EmployeLayout";

//pages
import Acceuil from "./pages/acceuil/Acceuil";
// import Login from "./pages/auth/Login";
// import Profile from "./pages/auth/Profile";

//---ventes
import VentesHome from "./pages/ventes/VentesHome";
import DevisNewForm from "./pages/ventes/devis/DevisNewForm";
import DevisExistingForm from "./pages/ventes/devis/DevisExistingForm";
import DevisProduitNewForm from "./pages/ventes/devis/DevisProduitNewForm";
import DevisListe from "./pages/ventes/devis/DevisListe";
import DevisDetails from "./pages/ventes/devis/DevisDetails";
import ProjetListe from "./pages/ventes/projets/ProjetListe";
import ProjetDetails from "./pages/ventes/projets/ProjetDetails";
import ProjetForm from "./pages/ventes/projets/ProjetForm";
import ClientListe from "./pages/ventes/clients/ClientListe";
import ClientDetails from "./pages/ventes/clients/ClientDetails";
import ClientForm from "./pages/ventes/clients/ClientForm";
import TransactionsNewForm from "./pages/ventes/transactions/TransactionsNewForm";
import TransactionsProduitNewForm from "./pages/ventes/transactions/TransactionsProduitNewForm";
import TransactionsListe from "./pages/ventes/transactions/TransactionsListe";
import TransactionsExistingForm from "./pages/ventes/transactions/TransactionsExistingForm";
import TransactionsDetails from "./pages/ventes/transactions/TransactionsDetails";

//---operations
import OperationsHome from "./pages/operations/OperationsHome";
import StockListe from "./pages/operations/stock/StockListe";
import StockDetails from "./pages/operations/stock/StockDetails";

import ArticleForm from "./pages/operations/articles/ArticleForm";
import ArticlePhotoForm from "./pages/operations/articles/ArticlePhotoForm";
import ArticleListe from "./pages/operations/articles/ArticleListe";
import ArticleDetails from "./pages/operations/articles/ArticleDetails";

import ProduitForm from "./pages/operations/produits/ProduitForm";
import ProduitPhotoForm from "./pages/operations/produits/ProduitPhotoForm";
import ProduitListe from "./pages/operations/produits/ProduitListe";
import ProduitDetails from "./pages/operations/produits/ProduitDetails";

import MagasinForm from "./pages/operations/magasin/MagasinForm";
import MagasinPhotoForm from "./pages/operations/magasin/MagasinPhotoForm";
import MagasinListe from "./pages/operations/magasin/MagasinListe";
import MagasinDetails from "./pages/operations/magasin/MagasinDetails";

//---personnel
import PersonnelHome from "./pages/personnel/PersonnelHome";
import EmployeForm from "./pages/personnel/employes/EmployeForm";
import EmployePhotoForm from "./pages/personnel/employes/EmployePhotoForm";
import EmployeListe from "./pages/personnel/employes/EmployeListe";
import EmployeDetails from "./pages/personnel/employes/EmployeDetails";
import CarteNewForm from "./pages/personnel/cartesEmployes/CarteNewForm";

//components

//functions and hooks
import { devisLoader } from "./functions/ventes/devisLoader";
import { devisProduitLoader } from "./functions/ventes/devisProduitLoader";
import { transactionLoader } from "./functions/ventes/transactionLoader";
import { transactionProduitLoader } from "./functions/ventes/transactionProduitLoader";
import { clientLoader } from "./functions/ventes/clientLoader";
import { stockLoader } from "./functions/operations/stockLoader";
import { articleLoader } from "./functions/operations/articleLoader";
import { produitLoader } from "./functions/operations/produitLoader";
import { magasinLoader } from "./functions/operations/magasinLoader";
import { employeLoader } from "./functions/personnel/employeLoader";
import { projetLoader } from "./functions/ventes/projetLoader";
import { carteEmployeLoader } from "./functions/personnel/carteEmployeLoader";
import { cartePhysiqueLoader } from "./functions/personnel/cartePhysiqueLoader";
import { authorizedUsersOnlyLoader } from "./functions/authotizedUsersOnlyLoader";

//state management
import { DevisArticlesContextProvider } from "./context/DevisArticlesContext";
import CarteDetails from "./pages/personnel/cartesEmployes/CarteDetails";
import CarteListe from "./pages/personnel/cartesEmployes/CarteListe";
import CarteEmployeLayout from "./layouts/personnel/CarteEmployeLayout";
import AnalyticsLayout from "./layouts/analytics/AnalyticsLayout";
import AnalyticsHome from "./pages/analytics/AnalyticsHome";
import AnalyticsStock from "./pages/analytics/AnalyticsStock";
import AnalyticsPersonnel from "./pages/analytics/AnalyticsPersonnel";
import AnalyticsStockTxns from "./pages/analytics/AnalyticsStockTxns";
import { stockTxnsLoader } from "./functions/operations/stockTxnsLoader";
import FinancesHome from "./pages/finances/FinancesHome";
import FinancesLayout from "./layouts/finances/FinancesLayout";
import CaisseLayout from "./layouts/finances/CaisseLayout";
import Etat from "./pages/finances/caisse/Etat";
import HistoriqueTransactions from "./pages/finances/caisse/HistoriqueTransactions";
import NouvelleTransaction from "./pages/finances/caisse/NouvelleTransaction";
import StockRetirer from "./pages/operations/stock/StockRetirer";
import StockAjouter from "./pages/operations/stock/StockAjouter";
//create router
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route index element={<Acceuil />} />

      <Route path="login" element={<Login />} />
      <Route
        path="signup"
        element={<Signup />}
        loader={authorizedUsersOnlyLoader}
      />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route
        path="password/reset/confirm/:uid/:token"
        element={<ResetPasswordConfirm />}
      />
      <Route path="activate/:uid/:token" element={<Activate />} />

      <Route path="ventes" element={<VentesLayout />}>
        <Route index element={<VentesHome />} />
        <Route path="devis" element={<DevisLayout />}>
          <Route index element={<DevisListe />} loader={devisLoader} />
          <Route
            path=":numeroDevis"
            element={<DevisDetails />}
            loader={devisLoader}
          />
          <Route
            path=":numeroDevis/modifier"
            element={<DevisExistingForm />}
            loader={devisProduitLoader}
          />
          <Route
            path="nouveau-devis/:projetId"
            element={<DevisNewForm />}
            loader={projetLoader}
          />
          <Route
            path=":numeroDevis/ajouter-produits"
            element={<DevisProduitNewForm />}
            loader={devisLoader}
          />
        </Route>
        <Route path="transactions" element={<TransactionLayout />}>
          <Route
            index
            element={<TransactionsListe />}
            loader={transactionLoader}
          />
          <Route
            path=":id"
            element={<TransactionsDetails />}
            loader={transactionLoader}
          />
          <Route
            path=":id/modifier"
            element={<TransactionsExistingForm />}
            loader={transactionProduitLoader}
          />
          <Route
            path="nouveau-transaction-vente/:clientId"
            element={<TransactionsNewForm />}
            loader={clientLoader}
          />
          <Route
            path=":id/ajouter-produits"
            element={<TransactionsProduitNewForm />}
            loader={transactionLoader}
          />
        </Route>
        <Route path="paiments" element={<EmployeLayout />}>
          <Route index element={<EmployeListe />} loader={employeLoader} />
          <Route
            path=":id"
            element={<EmployeDetails />}
            loader={employeLoader}
          />
          <Route
            path=":id/modifier"
            element={<EmployeForm new_ind="N" />}
            loader={employeLoader}
          />
          <Route
            path="nouveau-paiment"
            element={<EmployeForm new_ind="Y" />}
            loader={employeLoader}
          />
        </Route>
        <Route path="clients" element={<ClientLayout />}>
          <Route index element={<ClientListe />} loader={clientLoader} />
          <Route path=":id" element={<ClientDetails />} loader={clientLoader} />
          <Route
            path=":id/modifier"
            element={<ClientForm new_ind="N" />}
            loader={clientLoader}
          />
          <Route
            path="nouveau-client"
            element={<ClientForm new_ind="Y" />}
            loader={clientLoader}
          />
        </Route>
        <Route path="projets" element={<ProjetLayout />}>
          <Route index element={<ProjetListe />} loader={projetLoader} />
          <Route path=":id" element={<ProjetDetails />} loader={projetLoader} />
          <Route
            path=":id/modifier"
            element={<ProjetForm new_ind="N" />}
            loader={projetLoader}
          />
          <Route
            path="nouveau-projet/:id"
            element={<ProjetForm new_ind="Y" />}
            loader={clientLoader}
          />
        </Route>
      </Route>

      <Route path="personnel" element={<PersonnelLayout />}>
        <Route index element={<PersonnelHome />} />
        <Route path="employes" element={<EmployeLayout />}>
          <Route index element={<EmployeListe />} loader={employeLoader} />
          <Route
            path=":numeroEmploye"
            element={<EmployeDetails />}
            loader={employeLoader}
          />
          <Route
            path=":numeroEmploye/carte-employe"
            element={<CarteDetails />}
            loader={employeLoader}
          />
          <Route
            path=":numeroEmploye/modifier"
            element={<EmployeForm new_ind="N" />}
            loader={employeLoader}
          />
          <Route
            path=":numeroEmploye/modifier-photo"
            element={<EmployePhotoForm />}
            loader={employeLoader}
          />
          <Route
            path="nouvel-employe"
            element={<EmployeForm new_ind="Y" />}
            loader={employeLoader}
          />
          <Route
            path=":numeroEmploye/assigner-carte"
            element={<CarteNewForm />}
            loader={cartePhysiqueLoader}
          />
        </Route>
      </Route>

      <Route path="finances" element={<FinancesLayout />}>
        <Route index element={<FinancesHome />} />
        <Route path="caisse" element={<CaisseLayout />}>
          <Route index element={<Etat />} />
          <Route path="etat" element={<Etat />} />
          <Route
            path="nouvelle-transaction"
            element={<NouvelleTransaction />}
            loader={devisLoader}
          />
          <Route
            path="historique-transactions"
            element={<HistoriqueTransactions />}
          />
          {/* <Route
            path=":numeroEmploye"
            element={<EmployeDetails />}
            loader={employeLoader}
          />
          <Route
            path=":numeroEmploye/carte-employe"
            element={<CarteDetails />}
            loader={employeLoader}
          />
          <Route
            path=":numeroEmploye/modifier"
            element={<EmployeForm new_ind="N" />}
            loader={employeLoader}
          />
          <Route
            path=":numeroEmploye/modifier-photo"
            element={<EmployePhotoForm />}
            loader={employeLoader}
          />
          <Route
            path="nouvel-employe"
            element={<EmployeForm new_ind="Y" />}
            loader={employeLoader}
          />
          <Route
            path=":numeroEmploye/assigner-carte"
            element={<CarteNewForm />}
            loader={cartePhysiqueLoader}
          /> */}
        </Route>
      </Route>

      <Route path="operations" element={<OperationsLayout />}>
        <Route index element={<OperationsHome />} />
        <Route path="stock" element={<StockLayout />}>
          <Route index element={<StockListe />} loader={articleLoader} />
          <Route
            path=":articleId"
            element={<StockDetails />}
            loader={stockLoader}
          />
          <Route
            path="retirer"
            element={<StockRetirer />}
            loader={stockLoader}
          />
          <Route
            path="approvisionner"
            element={<StockAjouter />}
            loader={stockLoader}
          />
        </Route>
        <Route path="articles" element={<ArticleLayout />}>
          <Route index element={<ArticleListe />} loader={articleLoader} />
          <Route
            path=":codebarre"
            element={<ArticleDetails />}
            loader={articleLoader}
          />
          <Route
            path=":codebarre/modifier"
            element={<ArticleForm new_ind="N" />}
            loader={articleLoader}
          />
          <Route
            path=":codebarre/modifier-photo"
            element={<ArticlePhotoForm />}
            loader={articleLoader}
          />
          <Route
            path="nouvel-article"
            element={<ArticleForm new_ind="Y" />}
            loader={articleLoader}
          />
        </Route>

        <Route path="produits" element={<ProduitLayout />}>
          <Route index element={<ProduitListe />} loader={produitLoader} />
          <Route
            path=":id"
            element={<ProduitDetails />}
            loader={produitLoader}
          />
          <Route
            path=":id/modifier"
            element={<ProduitForm new_ind="N" />}
            loader={produitLoader}
          />
          <Route
            path=":id/modifier-photo"
            element={<ProduitPhotoForm />}
            loader={produitLoader}
          />
          <Route
            path="nouveau-produit"
            element={<ProduitForm new_ind="Y" />}
            loader={produitLoader}
          />
        </Route>

        <Route path="magasins" element={<MagasinLayout />}>
          <Route index element={<MagasinListe />} loader={magasinLoader} />
          <Route
            path=":id"
            element={<MagasinDetails />}
            loader={magasinLoader}
          />
          <Route
            path=":id/modifier"
            element={<MagasinForm new_ind="N" />}
            loader={magasinLoader}
          />
          <Route
            path=":id/modifier-photo"
            element={<MagasinPhotoForm />}
            loader={magasinLoader}
          />
          <Route
            path="nouveau-magasin"
            element={<MagasinForm new_ind="Y" />}
            loader={magasinLoader}
          />
        </Route>
      </Route>

      <Route path="analytics" element={<AnalyticsLayout />}>
        <Route index element={<AnalyticsHome />} />
        <Route path="stock" element={<AnalyticsStock />} loader={stockLoader} />
        <Route
          path="stock-txns"
          element={<AnalyticsStockTxns />}
          loader={stockTxnsLoader}
        />
        <Route
          path="personnel"
          element={<AnalyticsPersonnel />}
          loader={employeLoader}
        />
      </Route>
    </Route>
  )
);

function App() {
  const color = "white";

  document.body.style.backgroundColor = color;

  return (
    <Provider store={store}>
      <DevisArticlesContextProvider>
        <RouterProvider router={router} />
      </DevisArticlesContextProvider>
    </Provider>
  );
}

export default App;
