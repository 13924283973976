import { useEffect, useState } from "react";
import {
  Form,
  Link,
  NavLink,
  Outlet,
  redirect,
  useActionData,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { connect } from "react-redux";
import sendTransactionFormData from "../../../functions/ventes/sendTransactionFormData";

function TransactionNewForm({ user }) {
  const { clientId } = useParams();

  const client = useLoaderData();

  const thisClient = client.filter((obj) => obj.id == clientId)[0];

  const [formData, setFormData] = useState({});

  function handleChange(event) {
    const { name, value } = event.target;
    if (clientId != 0) setFormData({ client: clientId });
    setFormData((prevValues) => ({ ...prevValues, [name]: value }));
  }

  function handleSubmit(event) {
    event.preventDefault();

    sendTransactionFormData(
      {
        agentCommercial: user && user.employeNumber,
        ...formData,
      },
      process.env.REACT_APP_THE_HOST + "/api/transactionVente/",
      "POST",
      "new"
    );
  }

  return (
    <div className="w3-row repertoire-employe-layout">
      <div className="w3-third">
        <div
          className="w3-panel w3-text-white"
          style={{ backgroundColor: "gray" }}
        >
          <h3>
            Nouvelle vente -{" "}
            <em>
              veuillez fournir les infos ci-dessous{" "}
              <span style={{ color: "red" }}>(req.)</span>
            </em>
          </h3>
        </div>
        <div className="devis-entete">
          {clientId == 0 ? (
            <form method="post" onSubmit={handleSubmit}>
              <p>
                <label className="w3-text-black">
                  <b>
                    <span style={{ color: "red" }}>Date: </span>
                  </b>
                </label>
                <input
                  name="date"
                  type="date"
                  value={formData.date}
                  onChange={handleChange}
                  required={true}
                />
              </p>
              <p>
                <label className="w3-text-black">
                  <b>
                    <span style={{ color: "red" }}>Client: </span>
                  </b>
                </label>
                &nbsp;
                <select
                  name="client"
                  // value={formData.projet}
                  onChange={handleChange}
                  required={true}
                >
                  <option value={""}>selectionner client</option>
                  {client.map((x) => (
                    <option key={x.id} value={x.id}>
                      {x.prenom} {x.nom} / {x.nomEntreprise}
                    </option>
                  ))}
                </select>
                &nbsp;
                <NavLink
                  to={
                    process.env.REACT_APP_THE_HOST +
                    "/ventes/clients/nouveau-client"
                  }
                  target="_blank"
                >
                  <em>Ajouter nouveau client</em>
                </NavLink>
              </p>
              <p>
                <p>
                  <label className="w3-text-black">
                    <b>
                      Agent commercial:{" "}
                      <span style={{ backgroundColor: "gray", color: "white" }}>
                        {user && user.employeNumber}
                      </span>{" "}
                    </b>
                  </label>
                </p>
              </p>
              <button
                className="w3-btn w3-ripple w3-green w3-round"
                style={{ width: "300px" }}
                name="action"
                type="submit"
              >
                Ajouter des produits
              </button>
              <button
                className="w3-btn w3-ripple w3-light-grey w3-round w3-margin-left"
                style={{ width: "168px" }}
                onClick={() =>
                  window.location.replace(
                    process.env.REACT_APP_THE_HOST + "/ventes/transactions"
                  )
                }
              >
                Annuler
              </button>
            </form>
          ) : (
            <form method="post" onSubmit={handleSubmit}>
              <p>
                <label className="w3-text-black">
                  <b>
                    <span style={{ color: "red" }}>Date: </span>
                  </b>
                </label>
                <input
                  name="date"
                  type="date"
                  value={formData.date}
                  onChange={handleChange}
                  required={true}
                />
              </p>
              <p>
                <label className="w3-text-black">
                  <b>
                    <span style={{ color: "red" }}>Client: </span>
                  </b>
                </label>
                {thisClient &&
                  thisClient.prenom +
                    " " +
                    thisClient.nom +
                    "/ " +
                    thisClient.nomEntreprise}
              </p>
              <p>
                <p>
                  <label className="w3-text-black">
                    Agent commercial (ne peut etre change):{" "}
                    <span style={{ backgroundColor: "gray", color: "white" }}>
                      {user && user.employeNumber}
                    </span>{" "}
                  </label>
                </p>
              </p>
              <button
                className="w3-btn w3-ripple w3-green w3-round"
                style={{ width: "300px" }}
                name="action"
                type="submit"
              >
                Ajouter des produits
              </button>
              <button
                className="w3-btn w3-ripple w3-light-grey w3-round w3-margin-left"
                style={{ width: "168px" }}
                onClick={() =>
                  window.location.replace(
                    process.env.REACT_APP_THE_HOST + "/ventes/transactions"
                  )
                }
              >
                Annuler
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(TransactionNewForm);
