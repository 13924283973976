import { useState } from "react";
import {
  Form,
  Link,
  NavLink,
  Outlet,
  redirect,
  useActionData,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import useAPIData from "../../../functions/useAPIData";
import sendCarteNewFormData from "../../../functions/personnel/sendCarteNewFormData";

export default function CarteForm() {
  const { numeroEmploye, id } = useParams(); //get parameters

  const entity = useLoaderData(); //articles

  const nouvelleCarte = entity.filter((x) => x.IndAffectee != "O")[0];

  // const thisCarte = useAPIData("carteEmploye").filter((obj) => obj.id == id)[0];

  const thisEmploye = useAPIData("employe").filter(
    (obj) => obj.numeroEmploye == numeroEmploye
  )[0];

  const navigate = useNavigate();

  const today = new Date();

  //Variables for handling form
  let initValue = {
    cartePhysique: nouvelleCarte.id,
    employe: numeroEmploye,
    dateAffectee:
      today.getFullYear() +
      "-" +
      String(today.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(today.getDate()).padStart(2, "0"),
    dateExpiration: "9999-12-31",
  };

  const [formData, setFormData] = useState(initValue);

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData((prevValues) => ({ ...prevValues, [name]: value }));
  }

  function handleSubmit(event) {
    event.preventDefault();

    setFormData((prevValues) => ({
      ...prevValues,
      indPermanent: thisEmploye && thisEmploye.typeEmploi,
    }));

    sendCarteNewFormData(
      formData,
      process.env.REACT_APP_THE_HOST + "/api/carteEmploye/",
      "POST",
      "new"
    );
  }

  return (
    <div className="repertoire-employe-layout">
      <div style={{ width: "800px" }}>
        {/* The selection content */}

        <form method="post" onSubmit={handleSubmit}>
          <div
            className="w3-panel w3-text-white"
            style={{ backgroundColor: "gray" }}
          >
            <h1>Assigner nouvelle carte</h1>
            <h3>
              Veuillez fournir les informations ci-dessous{" "}
              <span style={{ color: "red" }}>(requis)</span>
            </h3>
          </div>
          <p>
            <label className="w3-text-black">
              <b>
                <span style={{ color: "red" }}>
                  Numero de la nouvelle carte
                </span>
              </b>
            </label>
            <input
              className="w3-input w3-border w3-sand"
              type="text"
              value={nouvelleCarte.numeroCarte}
              readOnly
            />
          </p>
          <p>
            <label className="w3-text-black">
              <b>
                <span style={{ color: "red" }}>Employe</span>
              </b>
            </label>
            <input
              className="w3-input w3-border w3-sand"
              type="text"
              value={
                thisEmploye &&
                thisEmploye.prenom +
                  " " +
                  thisEmploye.nom +
                  " (" +
                  numeroEmploye +
                  ")"
              }
              readOnly
            />
          </p>
          <p>
            <label className="w3-text-black">
              <b>
                <span style={{ color: "red" }}>Date d'affectation</span>
              </b>
            </label>
            <input
              className="w3-input w3-border w3-sand"
              name="dateAffectee"
              type="date"
              value={formData.dateAffectee}
              onChange={handleChange}
              required={true}
            />
          </p>
          <p>
            <label className="w3-text-black">
              <b>
                <span style={{ color: "red" }}>Date d'expiration</span>
              </b>
            </label>
            <input
              className="w3-input w3-border w3-sand"
              name="dateExpiration"
              type="date"
              value={formData.dateExpiration}
              onChange={handleChange}
              required={true}
            />
          </p>
          <p>
            <label className="w3-text-black">
              <b>
                <span style={{ color: "red" }}>Type emploi</span>
              </b>
            </label>
            <input
              className="w3-input w3-border w3-sand"
              type="text"
              value={thisEmploye && thisEmploye.typeEmploi}
              readOnly
            />
          </p>
          <button
            className="w3-btn w3-ripple w3-green w3-round"
            style={{ width: "500px" }}
            name="action"
            type="submit"
          >
            Soumettre
          </button>
          <button
            className="w3-btn w3-ripple w3-light-grey w3-round w3-margin-left"
            style={{ width: "284px" }}
            onClick={() =>
              navigate(
                "/personnel/employes/" +
                  numeroEmploye +
                  "/details/carte-employe"
              )
            }
          >
            Annuler
          </button>
        </form>
        <p></p>

        <p></p>
      </div>
    </div>
  );
}
