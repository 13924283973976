import { useLoaderData } from "react-router-dom";
import Card from "../../components/Card";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

const avgF = (arr) => {
  return arr.reduce((a, b) => a + b) / arr.length;
};

const medF = (arr) => {
  let values = [...arr].sort((a, b) => a - b);

  const half = Math.floor(values.length / 2);

  return values.length % 2
    ? values[half]
    : (values[half - 1] + values[half]) / 2;
};

export default function AnalyticsPersonnel() {
  const employes = useLoaderData();

  const count = employes.length;

  const avgS = numberWithCommas(
    Math.ceil(avgF(employes.map((x) => x.salaire)))
  );
  const medS = medF(employes.map((x) => x.salaire));

  return (
    <div className="w3-padding-small">
      <Card
        title="Personnel actif"
        icon="fa fa-users"
        number={count}
        color="w3-blue"
      />
      <Card
        title="Salaire moyen"
        icon="fa fa-money"
        number={avgS + " FCFA"}
        color="w3-orange"
        styles="w3-margin-left"
      />
    </div>
  );
}
