import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert"


export default function useAPIData(entity){

    const endpoint = process.env.REACT_APP_THE_HOST+'/api/'+entity+'/';
    const [objAll,setObjAll] = useState([]);

    // useEffect(() => {
    //     axios.get(endpoint,{
    //         headers: {
    //             'Authorization': `JWT ${localStorage.getItem('access')}`
    //         }
    //     }).then((response) =>{
    //         setObjAll(response.data);
    //     })
    // },[])

    useEffect(() => {
    const fetchObj = async () => {
    try {
        const response = await axios.get(endpoint,{
            headers: {
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        });
        setObjAll(response.data);
    } catch (error) {
        swal({
            text:"Erreur du serveur",
            icon:"error",
            buttons:false
        })
        setTimeout(function(){
            window.location.replace(process.env.REACT_APP_THE_HOST+"/")   
        }, 1500)
    }
    };
    fetchObj();
    }, []);

    return(objAll)

}