import { useState } from "react";
import { useLoaderData, useParams } from "react-router-dom";
import useAPIData from "../../../functions/useAPIData";
import deleteTransactionProduitData from "../../../functions/ventes/deleteTransactionProduitData";
import sendTransactionProduitFormData from "../../../functions/ventes/sendTransactionProduitFormData";
import sendTransactionFormData from "../../../functions/ventes/sendTransactionFormData";

function sortFunction(v1, v2) {
  return v1.numero - v2.numero;
}

export default function DevisExistingForm() {
  const { id } = useParams(); //get parameters

  const entity = useLoaderData(); //DevisProduit

  const theseProduit = entity
    .filter((x) => x.transaction == id)
    .sort(sortFunction);

  const thisTransaction = useAPIData("transactionVente").filter(
    (x) => x.id == id
  )[0];

  const thisClient = useAPIData("client").filter(
    (x) => thisTransaction && x.id == thisTransaction.client
  )[0];

  //Load other data sources (from API) as needed

  const produit = useAPIData("produit"); //all produits
  const couleur = useAPIData("couleurP"); //all couleurs

  const [transactionFormData, setTransactionFormData] = useState({
    remisePct: entity[0].remisePct,
  }); //useState(thisEntity); //Formdata

  const [produitData, setProduitData] = useState({}); //each produit data

  const [produitList, setProduitList] = useState(theseProduit);

  const [produitCount, setProduitCount] = useState(theseProduit.length + 1);

  const [montantTotal, setMontantTotal] = useState(0); //montant brut

  const [transactionUpdated, setTransactionUpdated] = useState(false); //indicator if devis has been updated

  const [produitUpdated, setProduitUpdated] = useState(false); //indicator if produit has been updated

  const [changeToTransaction, setChangeToTransaction] = useState(false);

  function getCouleur(couleurId) {
    const thisCouleur = couleur.filter((x) => x.id == couleurId)[0];

    return thisCouleur && thisCouleur.couleur;
  }

  function handleChangeA(event) {
    //produit data update
    const { name, value } = event.target;

    setProduitData((prevValues) => ({
      ...prevValues,
      [name]: value,
      transaction: id,
    }));
  }

  function handleChangeD(event) {
    //Devis form update
    const { name, value } = event.target;

    setTransactionFormData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setTransactionUpdated(false);
  }

  async function additionalData(produitId, largeur, hauteur, quantite) {
    //get this produit specifically
    const thisProduit = produit.filter((obj) => obj.id == produitId)[0];
    const thisCouleur =
      thisProduit && couleur.filter((obj) => obj.id == thisProduit.couleur)[0];

    //get the fields needed
    const reference = thisProduit && thisProduit.reference;

    const classe = thisProduit && thisProduit.classe;

    const designation = thisProduit && thisProduit.designation;

    const prix = thisProduit && thisProduit.prix;

    const couleur_ = thisCouleur && thisCouleur.couleur;

    const dimension_ = largeur + "x" + hauteur;

    // const largeur = thisDimension && thisDimension.largeur;

    // const hauteur = thisDimension && thisDimension.hauteur;

    // const photo = ... // add in version 2

    let montant = thisProduit && Number(thisProduit.prix) * Number(quantite);

    let prixUnitaire = thisProduit && Number(thisProduit.prix);

    if (largeur > 0 && hauteur > 0) {
      montant = montant * (((largeur / 1000) * hauteur) / 1000);
      prixUnitaire = prixUnitaire * (((largeur / 1000) * hauteur) / 1000);
    }

    //consolidate and return the data
    const data = {
      reference: reference,
      classe: classe,
      numero: produitCount,
      designation: designation,
      prix: prix,
      prixUnitaire: prixUnitaire,
      couleur_: couleur_,
      montant: montant,
      dimension_: dimension_,
      nouveauInd: "N",
    };

    return data;
  }

  function add(event) {
    event.preventDefault();

    setProduitCount((prevValues) => {
      return prevValues + 1;
    });

    additionalData(
      produitData.produit,
      produitData.largeur,
      produitData.hauteur,
      produitData.quantite
    )
      .then((data) =>
        setProduitData((prevValues) => ({ ...prevValues, ...data }))
      )
      .then(() => setProduitUpdated(true));
    setTransactionUpdated(false);
  }

  if (produitUpdated) {
    setProduitList([...produitList, produitData]);
    setProduitUpdated(false);
    setChangeToTransaction(true);
  }

  function remove(index) {
    let produitAfterIndex = produitList.filter((x, i) => i > index);
    let produitBeforeIndex = produitList.filter((x, i) => i < index);

    produitAfterIndex.map((x) => x.numero--);

    setProduitList(produitBeforeIndex.concat(produitAfterIndex));
    setProduitCount((prevValues) => prevValues - 1);
    setTransactionUpdated(false);
    setChangeToTransaction(true);
  }

  function updateTransaction() {
    setMontantTotal(
      produitList.reduce((a, { montant }) => Number(a) + Number(montant), 0)
    );
    setTransactionFormData((prevValues) => ({
      ...prevValues,
      montantBrut: produitList.reduce(
        (a, { montant }) => Number(a) + Number(montant),
        0
      ),
      montantRemise:
        (produitList.reduce(
          (a, { montant }) => Number(a) + Number(montant),
          0
        ) *
          transactionFormData.remisePct) /
        100,
      montantNet:
        produitList.reduce((a, { montant }) => Number(a) + Number(montant), 0) -
        (produitList.reduce(
          (a, { montant }) => Number(a) + Number(montant),
          0
        ) *
          transactionFormData.remisePct) /
          100,
    }));
    setTransactionUpdated(true);
  }

  // console.log(produitList);
  async function sendproduitData() {
    theseProduit.map((x, i) => {
      deleteTransactionProduitData(
        process.env.REACT_APP_THE_HOST +
          "/api/transactionVenteProduit/" +
          x.id +
          "/"
      );
    });
    produitList.map((x, i) => {
      sendTransactionProduitFormData(
        x,
        process.env.REACT_APP_THE_HOST + "/api/transactionVenteProduit/"
      );
    });
  }

  // console.log(produitList);

  async function handleSubmit() {
    if (changeToTransaction) {
      await sendproduitData();
    }

    await sendTransactionFormData(
      transactionFormData,
      process.env.REACT_APP_THE_HOST + "/api/transactionVente/" + id + "/",
      "PATCH",
      "existing"
    );
  }

  return (
    <div id="repertoire-employe-layout" className="w3-row">
      <div id="devis-produit-form" className="w3-half w3-animate-left">
        <div
          className="w3-panel w3-text-white"
          style={{ backgroundColor: "orange" }}
        >
          <h3>Veuillez ajouter les produits</h3>
        </div>
        <div id="ajouter-produit">
          <form method="post" onSubmit={add}>
            <p>
              <label className="w3-text-black w3-margin-right">
                <b>Produit No. </b>
                {produitCount} &nbsp;
              </label>
            </p>

            <p>
              <label className="w3-text-black w3-margin-right">
                <b>Designation (couleur)</b>
              </label>
              <select
                name="produit"
                value={produitData.produit}
                onChange={handleChangeA}
              >
                <option value={""}>selectionner produit</option>
                {produit.map((x) => {
                  const c = getCouleur(x.couleur);
                  return (
                    <option key={x.id} value={x.id}>
                      {x.designation} ({c})
                    </option>
                  );
                })}
              </select>
            </p>
            {/* <p>
              <label className="w3-text-black w3-margin-right">
                <b>Section</b>
              </label>
              <input
                className="w3-input w3-border w3-cell"
                name="section"
                type="text"
                value={produitData.section}
                style={{ width: "200px" }}
                onChange={handleChangeA}
              />
            </p> */}
            <p>
              <label className="w3-text-black w3-margin-right">
                <b>Dimension</b>
                &nbsp; largeur &nbsp;
                <input
                  className="w3-input w3-border w3-cell"
                  name="largeur"
                  type="text"
                  value={produitData.largeur}
                  style={{ width: "100px" }}
                  placeholder="mm"
                  onChange={handleChangeA}
                />
                &nbsp; hauteur &nbsp;
                <input
                  className="w3-input w3-border w3-cell"
                  name="hauteur"
                  type="text"
                  value={produitData.hauteur}
                  style={{ width: "100px" }}
                  placeholder="mm"
                  onChange={handleChangeA}
                />
              </label>
            </p>
            <p>
              <label className="w3-text-black w3-margin-right">
                <b>Quantite</b>
              </label>
              <input
                className="w3-input w3-border w3-cell"
                name="quantite"
                type="number"
                value={produitData.quantite}
                style={{ width: "100px" }}
                placeholder="unite"
                onChange={handleChangeA}
                required
              />
            </p>
            <button
              className="w3-btn w3-ripple w3-blue w3-round-xxlarge w3-margin-top"
              style={{ width: "200px" }}
              type="submit"
            >
              <i className="fa fa-plus-circle"></i> ajouter
            </button>
          </form>
        </div>
      </div>
      <div id="devis-produit-view" className="w3-half">
        <div
          id="devis-heading"
          className="w3-panel w3-text-white"
          style={{ backgroundColor: "gray" }}
        >
          <h3>
            Transaction No: <strong>{id}</strong>
          </h3>
          <p>
            Date:{" "}
            <strong>
              {thisTransaction && thisTransaction.date.substring(0, 10)}
            </strong>
          </p>
          <p>
            Client:{" "}
            <strong>
              {thisClient &&
                thisClient.prenom +
                  " " +
                  thisClient.nom +
                  "/ " +
                  thisClient.nomEntreprise}
            </strong>
          </p>
          <p>
            Agent commercial:{" "}
            <strong>
              {thisTransaction &&
                thisTransaction.agentCommercialPrenom +
                  " " +
                  thisTransaction.agentCommercialNom}
            </strong>
          </p>
        </div>
        <hr />

        <div id="devis-content" className="w3-white w3-margin-top">
          <table class="w3-table w3-bordered">
            <thead>
              <tr>
                <th>No.</th>
                <th>Designation</th>
                <th>Qte</th>
                <th>Pu</th>
                <th>Montant</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                /*produitList.sort(sortFunction).map((x) => ( */
                produitList.map((x, i) => {
                  return (
                    <tr>
                      <td>{x.numero}</td>
                      <td>{x.designation}</td>
                      <td>{x.quantite}</td>
                      <td>{x.prixUnitaire}</td>
                      <td>{x.montant}</td>
                      <td>
                        <button
                          className="w3-btn w3-red"
                          onClick={() => remove(i)}
                        >
                          enlever
                        </button>
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>

        {/* <div style={{ display: showSummary }}>
         */}
        <div>
          <hr />
          {/* <h3 className="w3-yellow w3-round w3-padding">
            Montant total = {montantTotal}
          </h3> */}
          <div id="devis-form">
            <form method="post">
              <h4>
                <label className="w3-text-black w3-margin-right">
                  Montant total hors TVA
                </label>
                <input
                  className="w3-input w3-border w3-cell w3-grey"
                  name="montantBrut"
                  value={montantTotal}
                  style={{ width: "300px" }}
                  readOnly
                />
              </h4>
              <h4>
                <label className="w3-text-black w3-margin-right">
                  Remise en %
                </label>
                <input
                  className="w3-input w3-border w3-cell"
                  name="remisePct"
                  value={transactionFormData.remisePct}
                  style={{ width: "300px" }}
                  placeholder="Eg. 15 pour 15%"
                  onChange={handleChangeD}
                />
              </h4>
              <h3>
                <label className="w3-text-black w3-margin-right">
                  Montant total net hors TVA
                </label>
                <input
                  className="w3-input w3-border w3-cell w3-light-blue"
                  name="montantNet"
                  value={transactionFormData.montantNet}
                  style={{ width: "300px" }}
                  readOnly
                />
              </h3>
            </form>
            <hr />
            <button
              className="w3-btn w3-ripple w3-purple w3-round w3-margin-top"
              style={{ width: "200px" }}
              onClick={() => updateTransaction()}
            >
              Rafraichir montants
            </button>
            <button
              className="w3-btn w3-ripple w3-green w3-round w3-margin-top w3-margin-left"
              style={{
                width: "300px",
                display: !transactionUpdated ? "none" : "",
              }}
              onClick={() => handleSubmit()}
            >
              Soumettre
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
