import { NavLink, Outlet, useOutletContext } from "react-router-dom";
import { connect } from "react-redux";
import NavbarModule from "../../components/NavbarModule";
import errorBackToAcceuil from "../../functions/errorBackToAcceuil";
import LoadingSpinner from "../../components/LoadingSpinner";

// function PersonnelLayout(props) {
function PersonnelLayout({ user }) {
  // console.log(accessLevel);
  return !user ? (
    <LoadingSpinner color="blue" show={true} />
  ) : user.accessPersonnel < 1 ? (
    errorBackToAcceuil()
  ) : (
    <div className="personnel-layout">
      <NavbarModule
        module={"Personnel"}
        w3color={"w3-purple"}
        icon={"fa fa-users"}
      />

      <Outlet />
    </div>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});
export default connect(mapStateToProps)(PersonnelLayout);
