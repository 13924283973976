import { Outlet } from "react-router-dom";
import SidebarFixed from "../../components/SidebarFixed";
import Breadcrumbs from "../../components/Breadcrumbs";

export default function AnalyticsLayout() {
  return (
    <div>
      <SidebarFixed />
      <div style={{ marginLeft: "15%" }}>
        <div class="w3-container w3-teal">
          <h1>Tableaux de bord</h1>
        </div>
        <Breadcrumbs />
        <div>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
