import { NavLink, Outlet } from "react-router-dom";
import { connect } from "react-redux";
import NavbarModule from "../../components/NavbarModule";

import errorBackToAcceuil from "../../functions/errorBackToAcceuil";

function VentesLayout({ user }) {
  return (
    // user && user.accessVentes > 0 ? (
    <div className="personnel-layout">
      <NavbarModule
        module={"Commercial"}
        w3color={"w3-blue"}
        icon={"fa fa-money"}
      />
      <Outlet />
    </div>
  );
  // ) : (
  //   errorBackToAcceuil()
  // );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps)(VentesLayout);
