import { useEffect, useState, useMemo, useRef, useCallback } from "react";
import {
  Link,
  NavLink,
  Outlet,
  useLoaderData,
  useNavigate,
} from "react-router-dom";
import useAPIData from "../../../functions/useAPIData";
// import Table from "../../components/ReactTable"
import { useReactToPrint } from "react-to-print";

const formatCurrency = (number) => {
  // Add thousands separator
  const formattedNumber = number
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  // Format the number as a currency string
  return `${formattedNumber}`;
};

export default function DevisListe() {
  const entity = useLoaderData(); //Articles

  const projet = useAPIData("projet");
  const employe = useAPIData("employe");

  const toPrint = useRef(null);

  const handlePrint = useReactToPrint({
    documentTitle: "Tous les devis",
    removeAfterPrint: true,
  });

  function getProjet(projetId) {
    const thisProjet = projet.filter((x) => x.id == projetId)[0];

    return thisProjet && thisProjet;
  }

  function getEmploye(numeroEmploye) {
    const thisAgentCommercial = employe.filter(
      (x) => x.numeroEmploye == numeroEmploye
    )[0];

    return thisAgentCommercial && thisAgentCommercial;
  }

  const [listeEntity, setListeEntity] = useState(entity);

  const navigate = useNavigate();

  function selectionEntity(numeroDevis) {
    navigate("" + numeroDevis);
  }

  function search(event) {
    const searchKey = event.target.value.toLowerCase();
    if (searchKey)
      setListeEntity(
        entity.filter((entity) => {
          const projet = getProjet(entity.projet);
          return (
            entity.numeroDevis.toLowerCase().includes(searchKey) ||
            (projet && projet.nom.toLowerCase().includes(searchKey)) ||
            (projet && projet.clientNom.toLowerCase().includes(searchKey)) ||
            (projet && projet.clientPrenom.toLowerCase().includes(searchKey)) ||
            (projet &&
              projet.clientEntreprise.toLowerCase().includes(searchKey))
          );
        })
      );
    else setListeEntity(entity);
  }

  return (
    <div className="repertoire-employe-layout">
      <div className="the-employe-table w3-cell" style={{ width: "100%" }}>
        <button
          onClick={() => {
            handlePrint(null, () => toPrint.current);
          }}
          className="w3-btn w3-ripple w3-pale-blue w3-round w3-margin-bottom"
          style={{ width: "300px" }}
        >
          <i className="fa fa-print" aria-hidden="true"></i> Imprimer
        </button>
        <input
          onChange={search}
          placeholder={"Entrez le numero de devis, nom du client ou du projet"}
          style={{ width: "100%", backgroundColor: "azure", height: "40px" }}
          autoFocus
        />
        <p></p>
        <div className="toPrint" ref={toPrint}>
          <div className="w3-card-4 w3-bar">
            <table className="w3-table-all w3-hoverable">
              <thead>
                <tr className="w3-light-grey">
                  <th>No.</th>
                  <th>Projet</th>
                  <th>Client</th>
                  <th>Responsable</th>
                  <th>Montant net (hors TVA)</th>
                  <th>Date créee</th>
                  <th>Date modifiée</th>
                </tr>
              </thead>
              <tbody>
                {listeEntity.map((entity) => {
                  const thisProjet = getProjet(entity.projet);
                  const thisAgentCommercial = getEmploye(
                    entity.agentCommercial
                  );

                  return (
                    <tr
                      key={entity.numeroDevis}
                      onClick={() => selectionEntity(entity.numeroDevis)}
                    >
                      <td>{entity.numeroDevis}</td>
                      <td>{thisProjet && thisProjet.nom}</td>
                      <td>
                        {thisProjet &&
                          thisProjet.clientPrenom +
                            " " +
                            thisProjet.clientNom +
                            "/ " +
                            thisProjet.clientEntreprise}
                      </td>
                      <td>
                        {thisAgentCommercial &&
                          thisAgentCommercial.prenom +
                            " " +
                            thisAgentCommercial.nom}
                      </td>
                      <td>{formatCurrency(entity.montantNet)}</td>
                      <td>{entity.date.substring(0, 10)}</td>
                      <td>{entity.dateMisAJour.substring(0, 10)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div
        className="the-employe-details w3-container w3-cell"
        style={{ width: "1000px" }}
      >
        <Outlet />
      </div>
    </div>
  );
}
