import { useState } from "react";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import useAPIData from "../../../functions/useAPIData";
import sendEmployeFormData from "../../../functions/personnel/sendEmployeFormData";
import errorBackToAcceuil from "../../../functions/errorBackToAcceuil";
import { connect } from "react-redux";
import LoadingSpinner from "../../../components/LoadingSpinner";

function EmployeForm({ user, new_ind }) {
  //Load other data sources (from API)
  const postes = useAPIData("poste");
  const departements = useAPIData("departement");
  const responsables = useAPIData("employe");

  const { numeroEmploye } = useParams();

  const navigate = useNavigate();

  const existingEmploye = useLoaderData().filter(
    (obj) => obj.numeroEmploye == numeroEmploye
  )[0];

  //Variables for handling form
  let initValue = {};
  if (new_ind == "N") {
    initValue = existingEmploye;
  } else {
    initValue = {
      dateNaissance: "1950-01-01",
      telephone: "+22320218460",
      adresseDomicile: "Bamako",
    };
  }

  const [formData, setFormData] = useState(initValue);

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData((prevValues) => ({ ...prevValues, [name]: value }));
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (new_ind == "Y") {
      sendEmployeFormData(
        formData,
        process.env.REACT_APP_THE_HOST + "/api/employe/",
        "POST",
        "new"
      );
    } else if (new_ind == "N") {
      sendEmployeFormData(
        formData,
        process.env.REACT_APP_THE_HOST +
          "/api/employe/" +
          formData.numeroEmploye +
          "/",
        "PATCH",
        "existing"
      );
    }
  }

  return !user ? (
    <LoadingSpinner color="blue" show={true} />
  ) : user.accessPersonnel < 3 ? (
    errorBackToAcceuil()
  ) : (
    <div className="repertoire-employe-layout">
      <div style={{ width: "800px" }}>
        {/* The selection content */}

        <form method="post" onSubmit={handleSubmit}>
          <div
            className="w3-panel w3-text-white"
            style={{ backgroundColor: "gray" }}
          >
            <h3>
              Veuillez fournir les informations ci-dessous
              <span style={{ color: "red" }}>(requis)</span>
            </h3>
          </div>
          <h5 className="w3-center">
            <em>
              <strong>
                <p>
                  ---------------------- Info. personnelles
                  ----------------------
                </p>
              </strong>
            </em>
          </h5>
          <p>
            <label className="w3-text-black">
              <b>
                <span style={{ color: "red" }}>Prénom</span>
              </b>
            </label>
            <input
              className="w3-input w3-border w3-sand"
              name="prenom"
              type="text"
              value={formData.prenom}
              onChange={handleChange}
              required={true}
            />
          </p>
          <p>
            <label className="w3-text-black">
              <b>
                <span style={{ color: "red" }}>Nom</span>
              </b>
            </label>
            <input
              className="w3-input w3-border w3-sand"
              name="nom"
              type="text"
              value={formData.nom}
              onChange={handleChange}
              required={true}
            />
          </p>
          <p>
            <label className="w3-text-black">
              <b>
                <span style={{ color: "red" }}>Date de naissance</span>
              </b>
            </label>
            <input
              className="w3-input w3-border w3-sand"
              name="dateNaissance"
              type="date"
              value={formData.dateNaissance}
              onChange={handleChange}
              required={true}
            />
          </p>
          <p>
            <label className="w3-text-black">
              <b>
                <span style={{ color: "red" }}>Sexe:</span>
              </b>
            </label>{" "}
            &nbsp;
            <select name="sexe" value={formData.sexe} onChange={handleChange}>
              <option value="H">Homme</option>
              <option value="F">Femme</option>
            </select>
          </p>
          <p>
            <label className="w3-text-black">
              <b>
                <span style={{ color: "red" }}>Adresse domicile</span>
              </b>
            </label>
            <input
              className="w3-input w3-border w3-sand"
              name="adresseDomicile"
              type="text"
              value={formData.adresseDomicile}
              onChange={handleChange}
              required={true}
            />
          </p>
          <p>
            <label className="w3-text-black">
              <b>
                <span style={{ color: "red" }}>Ville: </span>
              </b>
            </label>
            &nbsp;
            <select name="ville" value={formData.ville} onChange={handleChange}>
              <option value="Bamako">Bamako</option>
              <option value="Autre">Autre</option>
            </select>
          </p>
          <p>
            <label className="w3-text-black">
              <b>
                <span style={{ color: "red" }}>Téléphone principale</span>
              </b>
            </label>
            <input
              className="w3-input w3-border w3-sand"
              name="telephone"
              type="text"
              value={formData.telephone}
              onChange={handleChange}
              required={true}
            />
          </p>
          <p>
            <label className="w3-text-black">
              <b>Téléphone alternatif</b>
            </label>
            <input
              className="w3-input w3-border w3-sand"
              name="telephone2"
              value={formData.telephone2}
              onChange={handleChange}
              type="text"
            />
          </p>
          <p>
            <label className="w3-text-black">
              <b>Email</b>
            </label>
            <input
              className="w3-input w3-border w3-sand"
              name="email"
              value={formData.email}
              onChange={handleChange}
              type="email"
            />
          </p>

          <h5 className="w3-center">
            <em>
              <strong>
                <p>---------------------- Info. poste ----------------------</p>
              </strong>
            </em>
          </h5>
          <p>
            <label className="w3-text-black">
              <b>
                <span style={{ color: "red" }}>Poste: </span>
              </b>
            </label>
            &nbsp;
            <select
              name="poste"
              value={formData.poste}
              onChange={handleChange}
              required
            >
              <option value="">Selectionner poste</option>
              {postes.map((x) => (
                <option key={x.id} value={x.id}>
                  {x.nom}
                </option>
              ))}
            </select>
          </p>
          <p>
            <label className="w3-text-black">
              <b>
                <span style={{ color: "red" }}>Département: </span>
              </b>
            </label>
            &nbsp;
            <select
              name="departement"
              value={formData.departement}
              onChange={handleChange}
              required
            >
              <option value="">Selectionner departement</option>
              {departements.map((x) => (
                <option key={x.id} value={x.id}>
                  {x.nom}
                </option>
              ))}
            </select>
          </p>
          <p>
            <label className="w3-text-black">
              <b>Responsable:</b>
            </label>
            &nbsp;
            <select
              name="responsable"
              value={formData.responsable}
              onChange={handleChange}
            >
              <option value="">Selectionner responsable</option>
              {responsables.map((x) => (
                <option key={x.numeroEmploye} value={x.numeroEmploye}>
                  {x.prenom} {x.nom}
                </option>
              ))}
            </select>
          </p>
          <p></p>
          <h5 className="w3-center">
            <em>
              <strong>
                <p>
                  ---------------------- Termes d'emploi ----------------------
                </p>
              </strong>
            </em>
          </h5>
          <p>
            <label className="w3-text-black">
              <b>
                <span style={{ color: "red" }}>Date d'embauche</span>
              </b>
            </label>
            <input
              className="w3-input w3-border w3-sand"
              name="dateEmbauche"
              type="date"
              value={
                formData.dateEmbauche ? formData.dateEmbauche : "1991-01-01"
              }
              onChange={handleChange}
              required={true}
            />
          </p>
          <p>
            <label className="w3-text-black">
              <b>
                <span style={{ color: "red" }}>Type d'emploi:</span>
              </b>
            </label>{" "}
            &nbsp;
            <select
              name="typeEmploi"
              value={formData.typeEmploi}
              onChange={handleChange}
            >
              <option value="P">Permanent</option>
              <option value="C">Contractuel</option>
            </select>
          </p>
          {/* {
                            formData.typeEmploi=='C'?
                            <div>
                                <label className="w3-text-black"><b>Duree du contrat (en mois): </b></label>
                                <input className="w3-input w3-border w3-sand" name="dureeContrat" type="number" min={1} onChange={handleChange}/>
                            </div>
                            :""
                        } */}
          <p>
            <label className="w3-text-black">
              <b>
                <span style={{ color: "red" }}>Salaire de base</span>
              </b>
            </label>
            <input
              className="w3-input w3-border w3-sand"
              name="salaire"
              type="number"
              value={formData.salaire ? formData.salaire : 1}
              onChange={handleChange}
              required={true}
            />
          </p>
          <p>
            <label className="w3-text-black">
              <b>Bonus</b>
            </label>
            <input
              className="w3-input w3-border w3-sand"
              name="bonus"
              type="number"
              value={formData.bonus}
              onChange={handleChange}
            />
          </p>

          {new_ind == "N" ? (
            <div>
              <h5 className="w3-center">
                <em>
                  <strong>
                    ---------------------- Fin de l'emploi(?)
                    ----------------------
                  </strong>
                </em>
              </h5>
              <p>
                <label className="w3-text-black">
                  <b>Date de fin d'emploi</b>
                </label>
                <input
                  className="w3-input w3-border w3-sand"
                  name="dateFinEmploi"
                  type="date"
                  value={formData.dateFinEmploi}
                  onChange={handleChange}
                />
              </p>
              <p>
                <label className="w3-text-black">
                  <b>Raison:</b>
                </label>
                &nbsp;
                {/* <input className="w3-input w3-border w3-sand" name="raisonFinEmploi" type="text" value={formData.raisonFinEmploi} onChange={handleChange}  /></p> */}
                <select
                  name="raisonFinEmploi"
                  value={formData.raisonFinEmploi}
                  onChange={handleChange}
                >
                  <option value="n/a">Non-applicable</option>
                  <option value="V">Demission / Fin volontaire</option>
                  <option value="R">Retraite</option>
                  <option value="C">Fin de contrat</option>
                  <option value="I">Renvoi / Fin involontaire</option>
                </select>
              </p>
              <p>
                <label className="w3-text-black">
                  <b>Details additionnels</b>
                </label>
                <input
                  className="w3-input w3-border w3-sand"
                  name="detailsFinEmploi"
                  type="text"
                  value={formData.raisonFinDetails}
                  onChange={handleChange}
                />
              </p>
            </div>
          ) : (
            ""
          )}
          <button
            className="w3-btn w3-ripple w3-green w3-round"
            style={{ width: "500px" }}
            name="action"
            type="submit"
          >
            Soumettre
          </button>
          <button
            className="w3-btn w3-ripple w3-light-grey w3-round w3-margin-left"
            style={{ width: "284px" }}
            onClick={() =>
              new_ind == "Y"
                ? window.location.replace(
                    process.env.REACT_APP_THE_HOST + "/personnel/employes"
                  )
                : navigate(-1)
            }
          >
            Annuler
          </button>
        </form>
        <p></p>

        <p></p>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(EmployeForm);
