import { useState } from "react";
import { Link, Navigate, useLoaderData, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { signup } from "../actions/auth";
import swal from "sweetalert";

const Signup = ({ signup, isAuthenticated }) => {
  const authorizedUsersOnly = useLoaderData();

  const [accountCreated, setAccountCreated] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    employeNumber: "",
    password: "",
    re_password: "",
    accessPersonnel: "0",
    accessOperations: "0",
    accessVentes: "0",
  });

  const {
    name,
    email,
    employeNumber,
    password,
    re_password,
    accessPersonnel,
    accessOperations,
    accessVentes,
  } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    if (password === re_password) {
      const findAuthotizedUser = authorizedUsersOnly.filter(
        (x) =>
          x.email == email &&
          x.employeNumber == employeNumber &&
          x.is_authorized
      )[0];

      if (findAuthotizedUser) {
        signup(
          name,
          email,
          employeNumber,
          password,
          re_password,
          accessPersonnel,
          accessOperations,
          accessVentes
        );
        setAccountCreated(true);
      } else {
        swal({
          text: "Vous n'avez pas d'authorization d'acces.",
          icon: "error",
          buttons: false,
        });

        setTimeout(function () {
          window.location.replace("/login");
        }, 1500);
      }
    }
  };

  // Is the user authenticated?
  //Redirect to home page
  if (isAuthenticated) {
    swal({
      text: "Connecte!",
      icon: "success",
      buttons: false,
    });

    setTimeout(function () {
      window.location.replace("/");
    }, 1500);
  }
  if (accountCreated) {
    swal({
      text: "Requete soumise! Veuillez verifier votre email.",
      icon: "success",
      buttons: false,
    });

    setTimeout(function () {
      window.location.replace("/login");
    }, 3000);
  }

  return (
    <div className="acceuil">
      <div className="w3-bar w3-blue w3-center" style={{ height: "52px" }}>
        <NavLink to="/" style={{ textDecoration: "none" }}>
          <h3>
            Vial-Mali Admin <i class="fa fa-home" aria-hidden="true" />
          </h3>
        </NavLink>{" "}
      </div>
      <div className="w3-container w3-center w3-padding">
        <div className="w3-container margin-top">
          <h1>Requete d'acces</h1>
          <p>Veuillez fournir les informations requises ci-dessous</p>
          <form onSubmit={(e) => onSubmit(e)}>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="Pseudo*"
                name="name"
                value={name}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <p></p>
            <div className="form-group">
              <input
                className="form-control"
                type="email"
                placeholder="Email*"
                name="email"
                value={email}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <p></p>
            <div className="form-group">
              <input
                className="form-control"
                type="password"
                placeholder="Mot de passe*"
                name="password"
                value={password}
                onChange={(e) => onChange(e)}
                minLength="6"
                required
              />
            </div>
            <p></p>
            <div className="form-group">
              <input
                className="form-control"
                type="password"
                placeholder="Confirmer mot de passe*"
                name="re_password"
                value={re_password}
                onChange={(e) => onChange(e)}
                minLength="6"
                required
              />
            </div>
            <p></p>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="Numero d'employe*"
                name="employeNumber"
                value={employeNumber}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <p></p>
            <button className="w3-button w3-blue w3-round" type="submit">
              Soumettre requete
            </button>
          </form>
          <p className="mt-3">
            Vous avez un compte deja? <Link to="/login">Connectez-vous</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { signup })(Signup);
