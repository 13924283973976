import MenuButton from "../../components/MenuButton";

export default function FinancesHome() {
  return (
    <div className="personnel-page">
      <div className="w3-row">
        <div className="w3-container">
          <header class="w3-container w3-theme w3-padding" id="myHeader">
            <div class="w3-center">
              <p class="w3-xlarge w3-animate-bottom">
                Veuillez selectionner parmi les options suivantes
              </p>
            </div>
          </header>

          <div class="w3-row-padding w3-center w3-margin-top">
            <MenuButton
              title={"Caisse"}
              desc={"Effectuer transactions de caisse - depenses, recettes"}
              icon={"fa fa-archive"}
              // w3color={"w3-deep-orange"}
              link={"caisse"}
            />
            <MenuButton
              title={"Banques"}
              desc={
                "Effectuer transactions bancaires - virements, retraits, depots"
              }
              icon={"fa fa-university"}
              // w3color={"w3-deep-orange"}
              link={"employes"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
