import Breadcrumbs from "./Breadcrumbs";
import { NavLink } from "react-router-dom";
export default function NavbarModule({ module, w3color, icon }) {
  return (
    <div>
      <div className={"w3-bar w3-center " + w3color} style={{ height: "52px" }}>
        <NavLink to="" style={{ textDecoration: "none" }}>
          <h3>
            {module} <i class={icon}></i>
          </h3>
        </NavLink>
        <NavLink
          to="/"
          id="theNavLinkModule"
          style={{ textDecoration: "none" }}
          className={
            "w3-bar-item w3-button w3-xlarge w3-display-topright w3-red"
          }
        >
          Acceuil
          <i class="fa fa-home" aria-hidden="true" />
        </NavLink>
      </div>
      <Breadcrumbs />
    </div>
  );
}
