import {
  NavLink,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import useAPIData from "../../../functions/useAPIData";
import generique_employe from "../../../data_et_files/generique_employe.png";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { connect } from "react-redux";
import LoadingSpinner from "../../../components/LoadingSpinner";
import errorBackToAcceuil from "../../../functions/errorBackToAcceuil";
// import deleteEmployeData from "../../../functions/personnel/deleteEmployeData";

function EmployeDetails({ user }) {
  // const loc = useLocation().pathname

  const { numeroEmploye } = useParams();

  const employes = useLoaderData();

  const navigate = useNavigate();

  const employe = employes.filter(
    (obj) => obj.numeroEmploye == numeroEmploye
  )[0];

  const toPrint = useRef(null);

  const handlePrint = useReactToPrint({
    documentTitle: "Fiche d'employé de" + employe.prenom + " " + employe.nom,
    removeAfterPrint: true,
  });
  const poste = useAPIData("poste").filter((obj) => obj.id == employe.poste)[0];
  const departement = useAPIData("departement").filter(
    (obj) => obj.id == employe.departement
  )[0];
  const responsable = useAPIData("employe").filter(
    (obj) => obj.numeroEmploye == employe.responsable
  )[0];

  let display_employe_photo = null;

  if (employe.photo) {
    display_employe_photo = employe.photo.replace("media", "static/media");
  }

  return !user ? (
    <LoadingSpinner color="blue" show={true} />
  ) : user.accessPersonnel < 2 ? (
    errorBackToAcceuil()
  ) : (
    <div className="theEmployeDetails">
      <button
        onClick={() => {
          handlePrint(null, () => toPrint.current);
        }}
        className="w3-btn w3-ripple w3-pale-blue w3-round"
        style={{ width: "300px" }}
      >
        <i className="fa fa-print" aria-hidden="true"></i> Imprimer
      </button>
      <button
        onClick={() => navigate("modifier")}
        className="w3-btn w3-ripple w3-yellow w3-round w3-margin-left"
        style={{ width: "300px" }}
      >
        <i class="fa fa-pencil" aria-hidden="true"></i> Modifier
      </button>
      <NavLink
        to="carte-employe"
        className="w3-btn w3-ripple w3-red w3-round w3-margin-left"
        style={{ width: "300px" }}
      >
        <i class="fa fa-id-card-o" aria-hidden="true"></i> Carte employe
      </NavLink>
      <div className="toPrint" ref={toPrint}>
        <div
          className="w3-panel w3-text-white"
          style={{ backgroundColor: "gray" }}
        >
          <h3>
            Fiche d'employé de:{" "}
            <strong>
              {employe.prenom} {employe.nom}
            </strong>
          </h3>
        </div>
        <div className="w3-card-4 w3-bar">
          <div className="w3-bar-item w3-container w3-cell w3-center w3-padding-16">
            <button
              style={{
                backgroundColor: "white",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => navigate("modifier-photo")}
            >
              <img
                src={
                  display_employe_photo
                    ? display_employe_photo
                    : generique_employe
                }
                className="w3-hide-small w3-border"
                style={{ width: "500px", height: "500px" }}
                alt="#"
              />
              <br />
              Cliquer pour modifier la photo
            </button>
          </div>

          <div className="w3-bar-item w3-container w3-cell">
            <p>
              ---------------------- Info. personnelles ----------------------
            </p>
            <p>
              Prénom: <strong>{employe && employe.prenom}</strong>
            </p>
            <p>
              Nom: <strong>{employe.nom}</strong>
            </p>
            <p>
              No. d'employé: <strong>{employe.numeroEmploye}</strong>
            </p>

            <p>
              Status:{" "}
              <strong>
                {employe.dateFinEmploi != "9999-12-31" ? "Inactif" : "Actif"}
              </strong>
            </p>
            <p>Date de naissance: {employe.dateNaissance}</p>

            <p>Sexe: {employe.sexe}</p>
            <p>Adresse domicile: {employe.adresseDomicile}</p>

            <p>Ville: {employe.ville}</p>
            <p>
              Téléphone(s): {employe.telephone} / {employe.telephone2}{" "}
            </p>
            <p>Email: {employe.email}</p>
            <br />
            <p>---------------------- Info. poste ----------------------</p>
            <p>Poste: {poste && poste.nom}</p>
            <p>Département: {departement && departement.nom}</p>
            <p>
              Responsable: {responsable && responsable.prenom}{" "}
              {responsable && responsable.nom}
            </p>
            <br />
            <div>
              <p>
                ---------------------- Termes d'emploi ----------------------
              </p>
              <p>Date d'embauche: {employe.dateEmbauche}</p>
              <p>Type d'emploi: {employe.typeEmploi}</p>
              <p>Salaire de base: {employe.salaire}</p>
              <p>Bonus: {employe.bonus}</p>

              {employe.dateFinEmploi != "9999-12-31" ? (
                <div>
                  <br />
                  <p>
                    ---------------------- Fin d'emploi ----------------------
                  </p>
                  <p>Date de fin d'emploi: {employe.dateFinEmploi}</p>
                  <p>Raisons: {employe.raisonFinEmploi}</p>
                  <p>Détails additionnels: {employe.raisonFinDetails}</p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(EmployeDetails);
