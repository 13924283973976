import { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import {
  Link,
  NavLink,
  Outlet,
  useLoaderData,
  useNavigate,
} from "react-router-dom";
// import Table from "../../components/ReactTable"

function CaisseLayout({ user }) {
  const navigate = useNavigate();
  return (
    <div className="repertoire-employes-layout">
      <div className="w3-pannel w3-gray">
        <div className="w3-bar">
          <h3 className="w3-bar-item w3-text-white">
            <strong>
              Caisse <i class="fa fa-archive" aria-hidden="true" />
            </strong>
          </h3>
          <NavLink
            to="/finances/caisse/etat"
            id="theNavLinkMenu"
            className="w3-bar-item w3-button w3-ripple w3-light-gray w3-round w3-margin-left w3-margin-top"
          >
            <i className="fa fa-book" aria-hidden="true" /> Etat journalier
          </NavLink>
          <NavLink
            to="/finances/caisse/historique-transactions"
            id="theNavLinkMenu"
            className="w3-bar-item w3-button w3-ripple w3-light-gray w3-round w3-margin-left w3-margin-top"
          >
            <i className="fa fa-book" aria-hidden="true" /> Historique des
            transactions
          </NavLink>

          {/* {user && user.accessPersonnel > 1 ? ( */}
          <button
            onClick={() => navigate("/finances/caisse/nouvelle-transaction")}
            id="theNavLinkMenu"
            className="w3-bar-item w3-button w3-ripple w3-red w3-round w3-margin-left w3-margin-top"
          >
            <i className="fa fa-plus-circle" aria-hidden="true" /> Nouvelle
            transaction
          </button>
          <NavLink
            to="/finances"
            id="theNavLinkMenu"
            className="w3-bar-item w3-button w3-ripple w3-grey w3-round w3-margin-left w3-margin-top"
          >
            <i className="fa fa-chevron-circle-left" aria-hidden="true" />{" "}
            Retour au menu principal Finances
          </NavLink>
        </div>
      </div>
      <div className="w3-container w3-padding">
        <div className="the-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(CaisseLayout);
