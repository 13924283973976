import swal from "sweetalert";

export default async function sendTransactionStockFormData(formData, endpoint) {
  //Create csrftoken - Required for DJANGO!
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  const csrftoken = getCookie("csrftoken");

  let response;
  let result;

  try {
    response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken,
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
      body: JSON.stringify(formData),
    });

    result = await response.json();

    if (response.ok) {
      swal({
        text: "La quantite en stock a ete mis a jour avec SUCCESS!",
        icon: "success",
        buttons: false,
      });
      setTimeout(function () {
        window.location.replace(
          process.env.REACT_APP_THE_HOST + "/operations/stock/" + result.article
        );
      }, 1500);
    } else {
      swal({
        text: "Erreur du serveur",
        icon: "error",
        buttons: false,
      });
      setTimeout(function () {
        window.location.replace(
          process.env.REACT_APP_THE_HOST + "/operations/stock/" + result.article
        );
      }, 1500);
    }
  } catch (error) {
    swal({
      text: "Erreur du serveur",
      icon: "error",
      buttons: false,
    });
    setTimeout(function () {
      window.location.replace(
        process.env.REACT_APP_THE_HOST + "/operations/stock/"
      );
    }, 1500);
  }
}
