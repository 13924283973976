import { useNavigate } from "react-router-dom";

export default function BreadcrumbButton({ item, bold, index, pathList }) {
  const navigate = useNavigate();

  function goToPath(index) {
    let path = [];

    for (let x = 0; x < index + 1; x++) {
      if (x != "") path.push("/" + pathList[x]);
    }

    navigate(path.join(""));
  }

  return (
    <button
      className={
        "w3-bar-item w3-button w3-round-xxlarge w3-margin-right w3-border"
      }
      style={{
        fontWeight: bold ? "bolder" : "normal",
        backgroundColor: bold ? "gray" : "",
        color: bold ? "white" : "",
      }}
      onClick={() => goToPath(index)}
    >
      {item}
      &nbsp;
      <i class="fa fa-chevron-right" aria-hidden="true"></i>
      <i class="fa fa-chevron-right" aria-hidden="true"></i>
    </button>
  );
}
