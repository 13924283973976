// Before
// import React from 'react';
// import ReactDOM from 'react-dom';
// import App from './App';

// ReactDOM.render(
//   <App/>,
//   document.getElementById('root')
// )

// After
import { createRoot } from 'react-dom/client';
import App from './App';
// import "./data_et_files/favicon.ico"

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App/>);