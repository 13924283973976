import { useEffect, useState, useMemo, useRef, useCallback } from "react";
import {
  Link,
  NavLink,
  Outlet,
  useLoaderData,
  useNavigate,
} from "react-router-dom";
import useAPIData from "../../../functions/useAPIData";
// import Table from "../../components/ReactTable"
import { useReactToPrint } from "react-to-print";
import StockUpdateModal from "../../../components/StockUpdateModal";

export default function StockListe() {
  const articles = useLoaderData();

  const stock = useAPIData("stock");

  const couleur = useAPIData("couleurA");
  const classes = useAPIData("classeA");
  const unite = useAPIData("unite");

  const [changeDisplay, setChangeDisplay] = useState("none");
  // const magasin = useAPIData("magasin");

  const toPrint = useRef(null);

  const handlePrint = useReactToPrint({
    documentTitle: "Tout le stock",
    removeAfterPrint: true,
  });

  const articleStock = articles.map((x) => {
    let counter = 0;
    let totalQuantite = 0;
    let misAJourListe = [];
    x.inStock = "N";
    x.totalQuantite = 0;
    x.miseAJour = "";
    for (let i = 0; i < stock.length; i++) {
      if (stock[i].article === x.id) {
        counter++;
        totalQuantite += stock[i].quantiteCourante;
        misAJourListe.push(stock[i].dateDerniereMisAJour);
      }
    }
    if (counter > 0 && totalQuantite > 0) {
      x.inStock = "Y";
      x.totalQuantite = totalQuantite;
      if (misAJourListe.length == 1) x.miseAJour = misAJourListe[0];
      else x.miseAJour = Math.max(misAJourListe);
    } else if (counter > 0 && totalQuantite == 0) {
      if (misAJourListe.length == 1) x.miseAJour = misAJourListe[0];
      else x.miseAJour = Math.max(misAJourListe);
    }
    return x;
  });
  // .sort((a, b) => (a["designation"] > b["designation"] ? 1 : -1));

  const [searchedArticle, setSearchedArticle] = useState(articleStock);

  function getClasse(classeId) {
    const thisClasse = classes.filter((x) => x.id == classeId)[0];

    return thisClasse && thisClasse.classe;
  }

  function getCouleur(couleurId) {
    const thisCouleur = couleur.filter((x) => x.id == couleurId)[0];

    return thisCouleur && thisCouleur.couleur;
  }

  function getUnite(uniteId) {
    const thisUnite = unite.filter((x) => x.id == uniteId)[0];

    return thisUnite && thisUnite.unite;
  }

  // let sorted = searchedArticle;

  // function sortBy(att) {
  //   setSearchedArticle(sorted.sort((a, b) => (a[att] > b[att] ? 1 : -1)));
  // }

  const navigate = useNavigate();

  function selectionStock(articleId) {
    navigate("" + articleId);
  }
  function search(event) {
    const searchKey = event.target.value.toLowerCase();
    if (searchKey) {
      setSearchedArticle(
        articleStock &&
          articleStock.filter((a) => {
            return (
              a.designation.toLowerCase().includes(searchKey) ||
              a.reference.toLowerCase().includes(searchKey) ||
              a.codebarre == searchKey ||
              (a.fabriquant && a.fabriquant.toLowerCase().includes(searchKey))
            );
          })
      );
    } else setSearchedArticle(articleStock);
  }

  function change(event) {
    const code = event.target.value;
    if (code) {
      const a = articleStock.filter((a) => a.codebarre == code)[0];

      if (a) setChangeDisplay("block");
    }
  }

  // console.log(searchedArticle);

  return (
    <div className="repertoire-employe-layout">
      <div className="the-employe-table w3-cell" style={{ width: "100%" }}>
        <button
          onClick={() => {
            handlePrint(null, () => toPrint.current);
          }}
          className="w3-btn w3-ripple w3-pale-blue w3-round w3-margin-bottom"
          style={{ width: "300px" }}
        >
          <i className="fa fa-print" aria-hidden="true"></i> Imprimer
        </button>
        <p>
          <input
            onChange={search}
            placeholder={
              "Scannez un article ou recherchez article par nom, fabriquant ou reference"
            }
            style={{ width: "100%", backgroundColor: "azure", height: "40px" }}
            className="w3-round"
            // autoFocus
          />
        </p>

        <p></p>

        <div className="toPrint" ref={toPrint}>
          <div className="w3-card-4 w3-bar">
            <table className="w3-table-all w3-hoverable">
              <thead>
                <tr className="w3-light-grey">
                  <th>
                    {/* <button onClick={() => sortBy("designation")}> */}
                    Désignation
                    {/* </button> */}
                  </th>

                  <th>Fabriquant</th>
                  <th>Couleur</th>
                  <th>Catégorie</th>
                  <th>Quantité total disponible</th>
                  <th>Date dernière mis-a-jour</th>
                  <th>Reference</th>
                </tr>
              </thead>
              <tbody>
                {searchedArticle.map((a, k) => {
                  const classe = getClasse(a.classe);
                  const couleur = getCouleur(a.couleur);
                  const unite = getUnite(a.unite);

                  return (
                    <tr key={k} onClick={() => selectionStock(a.id)}>
                      <td>{a.designation}</td>

                      <td>{a.fabriquant}</td>
                      <td>{couleur && couleur}</td>
                      <td>{classe && classe}</td>

                      <td
                        style={{
                          backgroundColor:
                            a.totalQuantite > 0 ? "lightgreen" : "orange",
                        }}
                      >
                        <strong>
                          {a.totalQuantite} &nbsp; {unite && unite}
                        </strong>
                      </td>
                      <td>{a.miseAJour}</td>
                      <td>{a.reference}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <StockUpdateModal display={changeDisplay} />

      <div
        className="the-employe-details w3-container w3-cell"
        style={{ width: "1000px" }}
      >
        <Outlet />
      </div>
    </div>
  );
}
