import { useEffect, useState, useMemo, useRef, useCallback } from "react";
import {
  Link,
  NavLink,
  Outlet,
  useLoaderData,
  useNavigate,
} from "react-router-dom";
import { useReactToPrint } from "react-to-print";

export default function ClientListe() {
  const entity = useLoaderData(); //Articles

  const [listeEntity, setListeEntity] = useState(entity);

  const navigate = useNavigate();

  function selectionEntity(id) {
    navigate("" + id);
  }

  const toPrint = useRef(null);

  const handlePrint = useReactToPrint({
    documentTitle: "Tous les clients",
    removeAfterPrint: true,
  });

  function search(event) {
    const searchKey = event.target.value.toLowerCase();
    if (searchKey)
      setListeEntity(
        entity.filter((entity) => {
          return (
            entity.nom.toLowerCase().includes(searchKey) ||
            entity.prenom.toLowerCase().includes(searchKey) ||
            entity.nomEntreprise.toLowerCase().includes(searchKey)
          );
        })
      );
    else setListeEntity(entity);
  }

  return (
    <div className="repertoire-employe-layout">
      <div className="the-employe-table w3-cell" style={{ width: "100%" }}>
        <button
          onClick={() => {
            handlePrint(null, () => toPrint.current);
          }}
          className="w3-btn w3-ripple w3-pale-blue w3-round w3-margin-bottom"
          style={{ width: "300px" }}
        >
          <i className="fa fa-print" aria-hidden="true"></i> Imprimer
        </button>
        <input
          onChange={search}
          placeholder={"Entrez le nom du client ou de l'entreprise"}
          style={{ width: "100%", backgroundColor: "azure", height: "40px" }}
          autoFocus
        />
        <p></p>

        <div className="toPrint" ref={toPrint}>
          <div className="w3-card-4 w3-bar">
            <table className="w3-table-all w3-hoverable">
              <thead>
                <tr className="w3-light-grey">
                  <th>Prénom</th>
                  <th>Nom</th>
                  <th>Nom de l'entreprise</th>
                </tr>
              </thead>
              <tbody>
                {listeEntity.map((entity) => {
                  return (
                    <tr
                      key={entity.id}
                      onClick={() => selectionEntity(entity.id)}
                    >
                      <td>{entity.prenom}</td>
                      <td>{entity.nom}</td>
                      <td>{entity.nomEntreprise}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div
        className="the-employe-details w3-container w3-cell"
        style={{ width: "1000px" }}
      >
        <Outlet />
      </div>
    </div>
  );
}
