import swal from "sweetalert"

export const devisLoader = async () => {

    let response;
    let result;

    try{
        response = await fetch(process.env.REACT_APP_THE_HOST+'/api/devis/',{
            headers:{
                Authorization: `JWT ${localStorage.getItem('access')}`
            }
        })

        result = await response.json();
    }catch(error){
        swal({
            text:"Erreur du serveur",
            icon:"error",
            buttons:false
        })
        setTimeout(function(){
            window.location.replace(process.env.REACT_APP_THE_HOST+"/ventes/")   
        }, 1500)
    }    

    if(!response.ok){
        swal({
            text:"Erreur, les donnees ne sont pas disponible",
            icon:"error",
            buttons:false
        })
        setTimeout(function(){
            window.location.replace(process.env.REACT_APP_THE_HOST+"/ventes/")   
        }, 1500)
    }else{
        return result
    }    
}