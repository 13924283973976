import { useState } from "react";
import {
  useNavigate,
  useLocation,
  useParams,
  NavLink,
  useLoaderData,
} from "react-router-dom";
import swal from "sweetalert";

export default function EmployePhotoForm() {
  const { numeroEmploye } = useParams();

  const entity = useLoaderData();

  const thisEntity = entity.filter(
    (obj) => obj.numeroEmploye == numeroEmploye
  )[0];

  const navigate = useNavigate();

  const goTo_details = useLocation().pathname.replace("modifier-photo", "");

  const [photoData, setPhotoData] = useState(null);

  const handleChange = (event) => {
    const photo = event.target.files[0];
    // const file = event.target.files[0];
    // const reader = new FileReader()
    // reader.onload = () => {
    //     setPhotoData(reader.result)
    // }
    // if (file) {
    //     reader.readAsDataURL(file)
    // }
    // setPhotoData("localhost:8000/media/employePhotos/"+photo)
    setPhotoData(photo);
    // let formData = new FormData()
    // formData.append('file',photo)
    // setPhotoInd(true)
  };

  function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData();
    if (photoData) {
      formData.append("photo", photoData);

      function getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== "") {
          const cookies = document.cookie.split(";");
          for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === name + "=") {
              cookieValue = decodeURIComponent(
                cookie.substring(name.length + 1)
              );
              break;
            }
          }
        }
        return cookieValue;
      }

      const csrftoken = getCookie("csrftoken");

      const res = fetch(
        thisEntity &&
          process.env.REACT_APP_THE_HOST +
            "/api/employe/" +
            thisEntity.numeroEmploye +
            "/photo/",
        {
          method: "PUT",
          headers: {
            "X-CSRFToken": csrftoken,
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
          body: formData,
        }
      )
        .then(() => {
          swal({
            text: "La photo a ete charge avec SUCCES!",
            icon: "success",
            buttons: false,
          });

          setTimeout(function () {
            window.location.replace(goTo_details);
          }, 1500);
        })
        .catch((err) => {
          swal({
            text: "Erreur du serveur",
            icon: "error",
            buttons: false,
          });
          setTimeout(function () {
            window.location.replace(
              process.env.REACT_APP_THE_HOST + "/peronnel/employe/"
            );
          }, 1500);
        });
    } else
      swal({
        text: "Veuillez chargee une photo ou cliquez sur annuler pour retourner au detail du produit",
        icon: "error",
        // buttons:true
      });
  }

  return (
    <div className="repertoire-employe-layout">
      <div style={{ width: "800px" }}>
        {/* The selection content */}
        <div
          className="w3-panel w3-text-white"
          style={{ backgroundColor: "gray" }}
        >
          <h3>Veuillez fournir les informations ci-dessous</h3>
        </div>
        {/* <form method="post" onSubmit={handleSubmit(onSubmit)}> */}
        <form method="post" onSubmit={handleSubmit}>
          <h5 className="w3-center">
            <em>
              <strong>
                <p>
                  ---------------------- Photo de l'employe
                  ----------------------
                </p>
              </strong>
            </em>
          </h5>
          <label className="w3-text-black">
            <b>Photo:</b>
          </label>{" "}
          &nbsp;
          <input
            type="file"
            name="photo"
            accept="image/jpeg,image/png"
            onChange={handleChange}
          />
          {/* <input type="file" {...register("file")}/>        */}
          <p></p>
          <button
            className="w3-btn w3-ripple w3-green w3-round"
            style={{ width: "500px" }}
            name="action"
            type="submit"
          >
            Soumettre
          </button>
          <button
            className="w3-btn w3-ripple w3-light-grey w3-round w3-margin-left"
            style={{ width: "284px" }}
            onClick={() => navigate(-1)}
          >
            Annuler
          </button>
        </form>
        <p></p>
      </div>
    </div>
  );
}
