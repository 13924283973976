import MenuButton from "../../components/MenuButton";

export default function PersonnelHome() {
  return (
    <div className="personnel-page">
      <div className="w3-row">
        <div className="w3-container">
          <header class="w3-container w3-theme w3-padding" id="myHeader">
            <div class="w3-center">
              <p class="w3-xlarge w3-animate-bottom">
                Veuillez selectionner parmi les options suivantes
              </p>
            </div>
          </header>

          <div class="w3-row-padding w3-center w3-margin-top">
            <MenuButton
              title={"Employés"}
              desc={
                "Consulter repertoire des employés, ajouter nouvel employé et faire des modifications de profiles. Carte d'employé"
              }
              icon={"fa fa-users"}
              // w3color={"w3-deep-orange"}
              link={"employes"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
