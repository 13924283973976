import { NavLink } from "react-router-dom";
import MenuButton from "../../components/MenuButton";

export default function VentesHome() {
  return (
    <div className="personnel-page">
      <div className="w3-row">
        <div className="w3-container">
          <header class="w3-container w3-theme w3-padding" id="myHeader">
            <div class="w3-center">
              <p class="w3-xlarge w3-animate-bottom">
                Veuillez selectionner parmi les options suivantes
              </p>
            </div>
          </header>

          <div class="w3-row-padding w3-center w3-margin-top">
            <MenuButton
              title={"Transactions de vente"}
              desc={"Effectuer une vente et generer une facture payable"}
              icon={"fa fa-cart-arrow-down"}
              w3color={"w3-red"}
              link={"transactions"}
            />

            <MenuButton
              title={"Devis"}
              desc={"Consulter, créer, modifier et supprimer devis"}
              icon={"fa fa-file-text-o"}
              w3color={"w3-gray"}
              link={"devis"}
            />

            <MenuButton
              title={"Clients"}
              desc={
                "Consulter, enregister, modifier et supprimer profiles client"
              }
              icon={"fa fa-user"}
              w3color={"w3-light-gray"}
              link={"clients"}
            />

            <MenuButton
              title={"Tous les projets"}
              // desc={"Consulter, créer, modifier et supprimer projets"}
              icon={"fa fa-briefcase"}
              w3color={"w3-light-gray"}
              link={"projets"}
              addToClass={"w3-margin-top"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
