import { useEffect, useState } from "react";
import {
  Form,
  Link,
  NavLink,
  Outlet,
  redirect,
  useActionData,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import useAPIData from "../../../functions/useAPIData";
import sendDevisFormData from "../../../functions/ventes/sendDevisFormData";
import { connect } from "react-redux";

function DevisNewForm({ user }) {
  const { projetId } = useParams();

  const projet = useLoaderData();

  const thisProjet = projet.filter((obj) => obj.id == projetId)[0];

  const [formData, setFormData] = useState({});

  function handleChange(event) {
    const { name, value } = event.target;
    if (projetId != 0) setFormData({ projet: projetId });
    setFormData((prevValues) => ({ ...prevValues, [name]: value }));
  }

  function handleSubmit(event) {
    event.preventDefault();

    sendDevisFormData(
      {
        agentCommercial: user && user.employeNumber,
        ...formData,
      },
      process.env.REACT_APP_THE_HOST + "/api/devis/",
      "POST",
      "new"
    );
  }

  return (
    <div className="w3-row repertoire-employe-layout">
      <div className="w3-third">
        <div
          className="w3-panel w3-text-white"
          style={{ backgroundColor: "gray" }}
        >
          <h3>
            Nouveau devis -{" "}
            <em>
              veuillez fournir les infos ci-dessous{" "}
              <span style={{ color: "red" }}>(req.)</span>
            </em>
          </h3>
        </div>
        <div className="devis-entete">
          {projetId == 0 ? (
            <form method="post" onSubmit={handleSubmit}>
              <p>
                <label className="w3-text-black">
                  <b>
                    <span style={{ color: "red" }}>Date: </span>
                  </b>
                </label>
                <input
                  name="date"
                  type="date"
                  value={formData.date}
                  onChange={handleChange}
                  required={true}
                />
              </p>
              <p>
                <label className="w3-text-black">
                  <b>
                    <span style={{ color: "red" }}>Projet: </span>
                  </b>
                </label>
                &nbsp;
                <select
                  name="projet"
                  // value={formData.projet}
                  onChange={handleChange}
                  required={true}
                >
                  <option value={""}>selectionner projet</option>
                  {projet.map((x) => (
                    <option key={x.id} value={x.id}>
                      {x.nom}
                    </option>
                  ))}
                </select>
                &nbsp;
                <NavLink
                  to={
                    process.env.REACT_APP_THE_HOST +
                    "/ventes/projets/nouveau-projet/0"
                  }
                  target="_blank"
                >
                  <em>Creer nouveau projet</em>
                </NavLink>
              </p>
              <p>
                <p>
                  <label className="w3-text-black">
                    <b>
                      Agent commercial:{" "}
                      <span style={{ backgroundColor: "gray", color: "white" }}>
                        {user && user.employeNumber}
                      </span>{" "}
                    </b>
                  </label>
                </p>
              </p>
              <button
                className="w3-btn w3-ripple w3-green w3-round"
                style={{ width: "300px" }}
                name="action"
                type="submit"
              >
                Ajouter des produits
              </button>
              <button
                className="w3-btn w3-ripple w3-light-grey w3-round w3-margin-left"
                style={{ width: "168px" }}
                onClick={() =>
                  window.location.replace(
                    process.env.REACT_APP_THE_HOST + "/ventes/devis"
                  )
                }
              >
                Annuler
              </button>
            </form>
          ) : (
            <form method="post" onSubmit={handleSubmit}>
              <p>
                <label className="w3-text-black">
                  <b>
                    <span style={{ color: "red" }}>Date: </span>
                  </b>
                </label>
                <input
                  name="date"
                  type="date"
                  value={formData.date}
                  onChange={handleChange}
                  required={true}
                />
              </p>
              <p>
                <label className="w3-text-black">
                  <b>
                    <span style={{ color: "red" }}>Projet: </span>
                  </b>
                </label>
                <input
                  className="w3-border w3-sand"
                  name="projet"
                  type="text"
                  value={thisProjet && thisProjet.id}
                  hidden
                />
                {thisProjet && thisProjet.nom}
              </p>
              <p>
                <p>
                  <label className="w3-text-black">
                    Agent commercial:{" "}
                    <span style={{ backgroundColor: "gray", color: "white" }}>
                      {user && user.employeNumber}
                    </span>{" "}
                  </label>
                </p>
              </p>
              <button
                className="w3-btn w3-ripple w3-green w3-round"
                style={{ width: "300px" }}
                name="action"
                type="submit"
              >
                Ajouter des produits
              </button>
              <button
                className="w3-btn w3-ripple w3-light-grey w3-round w3-margin-left"
                style={{ width: "168px" }}
                onClick={() =>
                  window.location.replace(
                    process.env.REACT_APP_THE_HOST + "/ventes/devis"
                  )
                }
              >
                Annuler
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(DevisNewForm);
