import { useEffect, useState, useMemo, useRef, useCallback } from "react";
import {
  Link,
  NavLink,
  Outlet,
  useLoaderData,
  useNavigate,
} from "react-router-dom";
import useAPIData from "../../../functions/useAPIData";
// import Table from "../../components/ReactTable"

export default function ArticlesListe() {
  const entity = useLoaderData(); //Magasin

  const [listeEntity, setListeEntity] = useState(entity);

  const navigate = useNavigate();

  function selectionEntity(id) {
    navigate("" + id);
  }

  function search(event) {
    const searchKey = event.target.value.toLowerCase();
    if (searchKey)
      setListeEntity(
        entity.filter((entity) => {
          return (
            entity.nom.toLowerCase().includes(searchKey) ||
            entity.adresse.toLowerCase().includes(searchKey)
          );
        })
      );
    else setListeEntity(entity);
  }

  return (
    <div className="repertoire-employe-layout">
      <div className="the-employe-table w3-cell" style={{ width: "100%" }}>
        <input
          onChange={search}
          placeholder={"Recherchez magasin par nom ou adresse"}
          style={{ width: "100%", backgroundColor: "azure", height: "40px" }}
          autoFocus
        />
        <p></p>

        <div className="w3-card-4 w3-bar">
          <table className="w3-table-all w3-hoverable">
            <thead>
              <tr className="w3-light-grey">
                <th>Nom</th>
                <th>Adresse physique</th>
                <th>Capacité (en m3)</th>
              </tr>
            </thead>
            <tbody>
              {listeEntity.map((entity) => {
                return (
                  <tr
                    key={entity.id}
                    onClick={() => selectionEntity(entity.id)}
                  >
                    <td>{entity.nom}</td>
                    <td>{entity.adresse}</td>
                    <td>{entity.capacite}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div
        className="the-employe-details w3-container w3-cell"
        style={{ width: "1000px" }}
      >
        <Outlet />
      </div>
    </div>
  );
}
