import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function StockUpdateModal({ display }) {
  const [show, setShow] = useState(true);
  const navigate = useNavigate();
  return (
    <div className="w3-modal" style={{ display: show && display }}>
      <div class="w3-modal-content" style={{ width: "1000px" }}>
        <header class="w3-container w3-orange">
          <span
            onClick={() => navigate("")}
            class="w3-button w3-display-topright"
          >
            &times;
          </span>
          <h2>HEADER</h2>
        </header>
        <div>CONTENT</div>
      </div>
    </div>
  );
}
