import { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import {
  Link,
  NavLink,
  Outlet,
  useLoaderData,
  useNavigate,
} from "react-router-dom";
// import Table from "../../components/ReactTable"

function EmployeLayout({ user }) {
  const navigate = useNavigate();
  return (
    <div className="repertoire-employes-layout">
      <div className="w3-pannel w3-gray">
        <div className="w3-bar">
          <h3 className="w3-bar-item w3-text-white">
            <strong>
              Employés <i class="fa fa-users" aria-hidden="true" />
            </strong>
          </h3>
          {/* {user && user.accessPersonnel > 1 ? ( */}
          <button
            onClick={() => navigate("/personnel/employes/nouvel-employe")}
            id="theNavLinkMenu"
            className="w3-bar-item w3-button w3-ripple w3-red w3-round w3-margin-left w3-margin-top"
          >
            <i className="fa fa-user-plus" aria-hidden="true" /> Ajouter employé
          </button>
          {/* ) : (
            ""
          )} */}

          <NavLink
            to=""
            id="theNavLinkMenu"
            className="w3-bar-item w3-button w3-ripple w3-light-gray w3-round w3-margin-left w3-margin-top"
          >
            <i className="fa fa-book" aria-hidden="true" /> Repertoire des
            employés
          </NavLink>
          <NavLink
            to="/personnel"
            id="theNavLinkMenu"
            className="w3-bar-item w3-button w3-ripple w3-grey w3-round w3-margin-left w3-margin-top"
          >
            <i className="fa fa-chevron-circle-left" aria-hidden="true" />{" "}
            Retour au menu principal Personnel
          </NavLink>
        </div>
      </div>
      <div className="w3-container w3-padding">
        <div className="the-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(EmployeLayout);
