const Card = (props) => {
  return (
    <div class={"w3-quarter " + props.styles}>
      <div class={"w3-container w3-padding-16 w3-round-xxlarge " + props.color}>
        <div class="w3-left">
          <i class={props.icon + " w3-xxxlarge"}></i>
        </div>
        <div class="w3-right">
          <h2>{props.number}</h2>
        </div>
        <div class="w3-clear"></div>
        <h4>{props.title}</h4>
      </div>
    </div>
  );
};

export default Card;
